import React, { useState, useRef, useEffect } from "react";
import {
  Grid
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";


// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";

import {Dialog,DialogContent, DialogTitle} from "@material-ui/core";
import Aktualnosci from "../Aktualnosci/Aktualnosci";
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";


const HighlightedDatePicker = () => {

  const datePickerRef = useRef(null);

  useEffect(() => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  }, []);

  const highlightWithRanges = [
    { "react-datepicker__day--highlighted": [new Date(2023, 10, 1), new Date(2023, 10, 3)] },
    { "react-datepicker__day--highlighted": [new Date(2023, 11, 25), new Date(2023, 11, 29)] },
    { "react-datepicker__day--highlighted": [new Date(2024, 0, 1), new Date(2024, 0, 2)] },
    { "react-datepicker__day--highlighted": [new Date(2024, 1, 12), new Date(2024, 1, 23)] },
    { "react-datepicker__day--highlighted": [new Date(2024, 3, 1), new Date(2024, 3, 3)] },
    { "react-datepicker__day--highlighted": [new Date(2024, 3, 29), new Date(2024, 4, 3)] },
    { "react-datepicker__day--highlighted": [new Date(2024, 4, 30), new Date(2024, 4, 31)] },
    { "react-datepicker__day--highlighted": [new Date(2024, 5, 22), new Date(2024, 8, 30)] },
  ];

  return (
    <DatePicker
      id="date-picker"
      wrapperClassName="datepicker"
      ref={datePickerRef}
      className="datepicker"
      locale="pl"
      dateFormat="dd/MM/yyyy"
      highlightDates={highlightWithRanges}
      todayButton={"Dzisiaj"}
    fixedHeight
    label="Lekcja"
    popperPlacement="bottom"
      open
    />
  );
};

export default function Dashboard(props) {

  const [open, setOpen] = useState(false);
  const [choose, setChoose] = useState(0);

  const handleClickOpen = (wybor) =>
  {
    setChoose(wybor)
    setOpen(!open)
  }
  const plany = [
    "https://static.wixstatic.com/media/2a5f39_13d160fb9cf84008aee9fab1580c6a6d~mv2.jpg/v1/fill/w_980,h_693,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2a5f39_13d160fb9cf84008aee9fab1580c6a6d~mv2.jpg",
    "https://static.wixstatic.com/media/2a5f39_c4c115654525426ba15f859247636877~mv2.jpg/v1/fill/w_980,h_693,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2a5f39_c4c115654525426ba15f859247636877~mv2.jpg",
    "https://static.wixstatic.com/media/2a5f39_da60a0a14e2746a8a0a11b4709432f70~mv2.jpg/v1/fill/w_980,h_693,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2a5f39_da60a0a14e2746a8a0a11b4709432f70~mv2.jpg",
    "https://static.wixstatic.com/media/2a5f39_8aa2e0df3e9448aaaae1c6e8fc7a8619~mv2.jpg/v1/fill/w_980,h_693,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2a5f39_8aa2e0df3e9448aaaae1c6e8fc7a8619~mv2.jpg",
    "https://static.wixstatic.com/media/2a5f39_47b230f29ffc4565932b4ef93ceb20d1~mv2.jpg/v1/fill/w_980,h_693,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2a5f39_47b230f29ffc4565932b4ef93ceb20d1~mv2.jpg"

  ]
  return (
    <>
<Grid container>
<PageTitle title="Plan zajęć"></PageTitle>
<Widget style={{width:"100%", display:"flex",alignItems:"center",justifyContent:"center"}}>

  <Grid item xs >
  <img  onClick={()=> {handleClickOpen(0)}} style={{width:"100%"}} src="https://static.wixstatic.com/media/2a5f39_13d160fb9cf84008aee9fab1580c6a6d~mv2.jpg/v1/fill/w_980,h_693,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2a5f39_13d160fb9cf84008aee9fab1580c6a6d~mv2.jpg" alt="PlanZajec"/>
  <img  onClick={()=> {handleClickOpen(1)}} style={{width:"100%"}} src="https://static.wixstatic.com/media/2a5f39_c4c115654525426ba15f859247636877~mv2.jpg/v1/fill/w_980,h_693,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2a5f39_c4c115654525426ba15f859247636877~mv2.jpg" alt="PlanZajec"/>
  <img onClick={()=> {handleClickOpen(2)}} style={{width:"100%"}} src="https://static.wixstatic.com/media/2a5f39_da60a0a14e2746a8a0a11b4709432f70~mv2.jpg/v1/fill/w_980,h_693,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2a5f39_da60a0a14e2746a8a0a11b4709432f70~mv2.jpg" alt="PlanZajec"/>
  <img onClick={()=> {handleClickOpen(3)}} style={{width:"100%"}} src="https://static.wixstatic.com/media/2a5f39_8aa2e0df3e9448aaaae1c6e8fc7a8619~mv2.jpg/v1/fill/w_980,h_693,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2a5f39_8aa2e0df3e9448aaaae1c6e8fc7a8619~mv2.jpg" alt="PlanZajec"/>
  <img  onClick={()=> {handleClickOpen(4)}} style={{width:"100%"}} src="https://static.wixstatic.com/media/2a5f39_47b230f29ffc4565932b4ef93ceb20d1~mv2.jpg/v1/fill/w_980,h_693,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2a5f39_47b230f29ffc4565932b4ef93ceb20d1~mv2.jpg" alt="PlanZajec"/>
   
   
    <Dialog fullScreen open={open} onClick={()=> {setOpen(!open)}}>
    <DialogContent >
    <img
            
            src={plany[choose]}
            alt="PlanZajec"
          />
    </DialogContent>
    </Dialog>
  
  
  
  
  </Grid>
  </Widget>
</Grid>


    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
