import {React, useEffect, useRef, useState, useReducer} from "react";
import { Grid, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import {Checkbox, Typography} from "@material-ui/core";
import {Input} from "@material-ui/core";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { DataGrid, GridColDef, GridValueGetterParams} from '@material-ui/data-grid';
// data
import mock from "../dashboard/mock";
import { createTheme } from '@material-ui/core/styles'
import MenuButton  from "@material-ui/icons/Menu"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";
import {
  Menu,
  MenuItem,
  FormControlLabel,
  FormGroup,
  FormControl
} from "@material-ui/core";
import axios from 'axios';
import './styles.css';
import { ToastContainer, toast } from 'react-toastify';
import ManagerLekcjiStary from "./ManagerLekcjiStary";
const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },
  datepicker: {
    backgroundColor: theme.palette.primary.main,
  },

}))



export default function ManagerLekcji() {
  const classes = useStyles();

  const [groupUsersVisibility, setGroupUsersVisibility] = useState({});
  const [groupUsersData, setGroupUsersData] = useState({});
  const [groupName, setGroupName] = useState("");
  const [groups, setGroups] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [deletedLessonIndex, setDeletedLessonIndex] = useState(null);
  const [groupNames, setGroupNames] = useState({});
  const [lessonsWithGroupNames, setlessonsWithGroupNames] = useState([])

  
  const fetchGroupNames = async () => {
    try {
      const response = await axios.get(
        "https://mobileappscrapper.herokuapp.com/Eko_get_all_groups"
      );

      if (response.status === 200) {
        const groupNamesData = response.data;
        setGroupNames(groupNamesData);
      } else {
        toast.error("Failed to fetch group names");
      }
    } catch (error) {
      toast.error("An error occurred while fetching group names");
    }
  };


  const toggleGroupUsers = (dataIndex, usersInGroup) => {
    setGroupUsersVisibility(prevVisibility => ({
      ...prevVisibility,
      [dataIndex]: !prevVisibility[dataIndex]
    }));

    setGroupUsersData(prevData => ({
      ...prevData,
      [dataIndex]: usersInGroup
    }));
  };
  const handleCreateGroup = async () => {
    const newGroup = {
      name: groupName,
    };

    try {
      const response = await axios.post(
        "https://mobileappscrapper.herokuapp.com/Eko_create_group",
        newGroup
      );

      if (response.status === 200) {
        toast.success("Grupa stworzona poprawnie!");
        // Clear input field after successful creation
        setGroupName("");
        // Refresh group list
        fetchGroups();
      }
    } catch (error) {
      toast.error("Wystąpił błąd podczas tworzenia grupy!");
    }
  };
  const fetchUsersForGroup = async (group) => {
    try {
      const response = await axios.get(
        "https://mobileappscrapper.herokuapp.com/Eko_get_all_users"
      );
  
      const usersData = response.data;
      const userIds = group.users;
      const usersInGroup = userIds.map(userId => usersData[userId] || null);
      return usersInGroup;
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania użytkowników grupy");
      return [];
    }
  };


  
  const fetchAllUsers = async () => {
    try {
      const response = await axios.get(
        "https://mobileappscrapper.herokuapp.com/Eko_get_all_users"
      );
      const usersArray = Object.values(response.data);
      return usersArray;
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania użytkowników");
      return [];
    }
  };
  const fetchGroups = async () => {
    try {
      const response = await axios.get(
        "https://mobileappscrapper.herokuapp.com/Eko_get_all_groups"
      );

      const parsedGroups = Object.entries(response.data).map(([id, groupString]) => ({
        id,
        ...JSON.parse(groupString)
      }));

      setGroups(parsedGroups);
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania grup!");
    }
  };

  const fetchLessons = async () => {
    try {
      const response = await axios.get(
        "https://mobileappscrapper.herokuapp.com/get_global_lessons"
      );
  
      if (response.status === 200) {
        const lessonsData = response.data.entries;
  
        // Fetch group names and wait for the response
        const groupNamesResponse = await axios.get("https://mobileappscrapper.herokuapp.com/Eko_get_all_groups");
        if (groupNamesResponse.status === 200) {
          const groupNamesData = groupNamesResponse.data;
          setGroupNames(groupNamesData);
  
          // Update lessons with group names
          const lessonsWithNames = lessonsData.map((lesson) => ({
            ...lesson,
            Grupa: groupNamesData[lesson.Grupa] || lesson.Grupa,
          }));
  
          setlessonsWithGroupNames(lessonsWithNames);
          setLessons(lessonsWithNames);
        } else {
          toast.error("Failed to fetch group names");
        }
      } else {
        toast.error("Failed to fetch lessons data");
      }
    } catch (error) {
      toast.error("An error occurred while fetching lessons data");
    }
  };



  useEffect(() => {
    if (deletedLessonIndex !== null) {
      handleDeleteLesson(deletedLessonIndex);
    }
  }, [deletedLessonIndex]);

  const handleDeleteLesson = async (lessonIndex) => {
    const lesson = lessons[lessonIndex];
    
    let lessonName;
    try {
      lessonName = JSON.parse(lesson.Grupa);
    } catch (error) {
      // If parsing fails, provide groupId directly
      lessonName = { id: lesson.Grupa };
    }
  
    const requestData = {
      Grupa: lessonName.id,
      Data: lesson.Data,
    };
  
    try {
      const response = await axios.delete(
        "https://mobileappscrapper.herokuapp.com/delete_lessons",
        {
          data: requestData,
        }
      );
  
      if (response.status === 200) {
        toast.success("Lekcja została usunięta!");
        // Remove the deleted lesson from the lessons state
        const updatedLessons = [...lessons];
        updatedLessons.splice(lessonIndex, 1);
        setLessons(updatedLessons);
        // Reset the deletedLessonIndex state
        setDeletedLessonIndex(null);
      } else {
        toast.error("Failed to delete lesson");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the lesson");
    }
  };

  const fetchUserById = async (userId) => {
    try {
      const response = await axios.get(
        `https://mobileappscrapper.herokuapp.com/Eko_get_all_users`
      );

      const usersData = response.data;
      const user = usersData[userId];
      return user;
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania użytkownika");
      return null;
    }
  };
  
  const populateGroupUsersData = async () => {
    const populatedData = {};
  
    await Promise.all(groups.map(async (group) => {
      const usersInGroup = await fetchUsersForGroup(group);
      populatedData[group.id] = usersInGroup;
    }));
  
    setGroupUsersData(populatedData);
  };
  
  useEffect(() => {
    populateGroupUsersData();
  }, [groups]);

  const filterParticipantsByObecnosc = (participants) => {
    return participants.filter((participant) => participant.obecnosc === true);
  };

  function decodeUnicode(str) {
    return str.replace(/\\u[0-9a-fA-F]{4}/g, (match) => String.fromCharCode(parseInt(match.substr(2), 16)));
  }
  
  return (
    <>
      <Grid container spacing={4}>
        <ToastContainer />
       
        <Grid item xs={12}>
        <MUIDataTable
  title={"Lista Lekcji"}
  data={lessons}
  options={{

    textLabels: {
      body: {
        noMatch: "Niestety, nie znaleziono pasujących rekordów",
      },
        pagination: {
          next: "Następna strona",
          previous: "Poprzednia strona",
          rowsPerPage: "Wiersze na stronę:",
          displayRows: "z" // 1-10 of 30
        },
        toolbar: {
          search: "Szukaj",
          downloadCsv: "Pobierz CSV",
          print: "Drukuj",
          viewColumns: "Zobacz kolumny",
          filterTable: "Filtruj tabelę"
        },
        filter: {
          title: "FILTRY",
          reset: "reset",          
        },
        viewColumns: {
          title: "Pokaż kolumny"
        },
        selectedRows: {
          text: "wierszy usuniętych",
          delete: "Usuń"
        }
    },
  }}
  columns={[
    {
      name: "Data",
      label: "Data",
    },
    {
      name: "Grupa",
      label: "Grupa",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const lesson = lessons[dataIndex];
          const groupId = lesson.Grupa;
          let groupName = groupId; // Default to the original ID if parsing fails
    
          try {
            const groupNameObject = JSON.parse(groupId);
            if (groupNameObject && groupNameObject.name) {
              const nameWithSpecialChars = groupNameObject.name;
              groupName = decodeUnicode(nameWithSpecialChars);
            }
          } catch (error) {
            // Handle any potential JSON parsing errors
            console.error("Error parsing JSON:", error);
          }
    
          return groupName;
        },
      },
    },
    {
      name: "Podpis",
      label: "Podpis",
    },
    {
      name: "Temat",
      label: "Temat",
    },
    {
      name: "Uczestnicy",
      label: "Uczestnicy",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const lesson = lessons[dataIndex];
          const participants = lesson.Uczestnicy;

          if (participants && participants.length > 0) {
            return (
              <table>
                <thead>
                  <tr>
                    <th>Imię i nazwisko</th>
                    <th>Obecny</th>
                    <th>Spóźniony</th>
                    <th>Odrabianie</th>
                    <th>Uwagi</th>
                  </tr>
                </thead>
                <tbody>
                  {participants.map((participant, index) => (
                    <tr key={index}>
                      <td>{participant.name}</td>
                      <td>{participant.obecnosc ? '✓' : 'x'}</td>
                      <td>{participant.spozniony ? '✓' : 'x'}</td>
                      <td>{participant.odrabianie ? '✓' : 'x'}</td>
                      <td>{participant.uwagi}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            );
          } else {
            return "Brak uczestników!";
          }
        },
      },
    },
    {
      name: "delete",
      label: "Usuń",
      options: {
        customBodyRenderLite: (dataIndex) => (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setDeletedLessonIndex(dataIndex)}
          >
            USUŃ
          </Button>

  )},
        }]}
  

      

/>
<div style={{textAlign:"center", alignItems:"center", justifyContent:"center", marginTop:"20px"}}>
 <Button variant="contained" color="primary" style={{color:"white", textAlign:"center"}} onClick={fetchLessons}>Wczytaj</Button>
        </div>
       
        </Grid>

      </Grid>
      <div style={{marginTop:"100px"}}>
      <ManagerLekcjiStary></ManagerLekcjiStary>
        </div>

    </>
  );
}