import {React, useEffect, useRef, useState, useReducer} from "react";
import { Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import {Checkbox, TextField, Button, Typography} from "@material-ui/core";
import {Input} from "@material-ui/core";
// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import {Table} from "@material-ui/core";
import { DataGrid, GridColDef, GridValueGetterParams} from '@material-ui/data-grid';
// data
import mock from "../dashboard/mock";
import { createTheme } from '@material-ui/core/styles'
import {DatePicker, DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MenuButton  from "@material-ui/icons/Menu"
import {
  Paper,
  Menu,
  MenuItem,
} from "@material-ui/core";
import axios from 'axios';


const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))


const TabelaFrekwencji = (props) =>
{
  const nazwagrupy = props.grupa;
  let data = [];
  let DB = 'https://mobileappscrapper.herokuapp.com/getwholedb/damian';
  var Frekwencja = [];
  axios.get(DB)
        .then(response => data = response.data[0])
        .then(response => console.log(data))
        .finally(()=>
          {
            for(let element in data)
            {
              if(element.startsWith(props.grupa))
              {
               var a =JSON.parse(data[element].Lekcja)
               for(let elements in a.Uczestnicy)
               {
                console.log(a.Uczestnicy[elements].name)
                Frekwencja[elements] = {
                  "Imię": a.Uczestnicy[elements].name,
                  "obecnosc": a.Uczestnicy[elements].obecnosc
                }
               }
              }
              console.log(Frekwencja)
            }
          }
        )

  return (

    <>
    <h1>Działam!</h1>
    </>
  )
}

export default function BaletKlasaIAIB_Generalne() {





    return (
      <>
       
        <Grid>
        <PageTitle title="Balet Klasa IA-IB" />
        <Typography></Typography>
        
        <Widget>
         
  <TabelaFrekwencji grupa="BaletGrupaWA"></TabelaFrekwencji>
          
       </Widget>
          </Grid>
          
  
          
    
      
  
     
        </>
        
    );
  }
  