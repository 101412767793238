import {React, useEffect, useRef, useState, useReducer, forwardRef} from "react";
import { Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import {Select, Checkbox, TextField, Button, Typography, Zoom, useScrollTrigger } from "@material-ui/core";
import {Input} from "@material-ui/core";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import {Table} from "@material-ui/core";
import { DataGrid, GridColDef, GridValueGetterParams} from '@material-ui/data-grid';
// data
import mock from "../dashboard/mock";
import { createTheme } from '@material-ui/core/styles'
import MenuButton  from "@material-ui/icons/Menu"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";
import {
  Paper,
  Menu,
  MenuItem,
  FormControlLabel,
  FormGroup,
  FormControl,


} from "@material-ui/core";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import axios from 'axios';
import './styles.css';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useLocation } from 'react-router-dom';
import { subDays, addDays, format, parse } from 'date-fns';
import { Sync as Sync,
  Backspace as Backspace,
  Delete as DeleteOutlined,
  Save as Save,
  Edit as Edit,
  Add as Add,
  Remove as Remove,
  KeyboardArrowUp as KeyboardArrowUpIcon

} from "@material-ui/icons"
import { useTour } from "@reactour/tour";


const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },
 

}))


const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 1,
  });

  useEffect(() => {
    // Show/hide the button based on the scroll position
    setVisible(trigger);
  }, [trigger]);

  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Zoom in={visible}>
      <Button
        onClick={handleClick}
        style={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        color="primary"
        size="large"
        aria-label="do gory"
      >
        <KeyboardArrowUpIcon />
      </Button>
    </Zoom>
  );
};


const DataTable = ({ Uczestnicy, handleObecnosc, handleSpozniony, handleOdrabianie, handleUwagi, active }) => {
  const columns = [
    {
      name: 'correctIndex',
      label: 'ID',
    },
    {
      name: 'imie',
      label: 'Imię',
      options: {
        customBodyRender: (value, tableMeta) => {
          const participant = data[tableMeta.rowIndex];
          const isActive = data && data[tableMeta.rowIndex].active !== undefined ? data[tableMeta.rowIndex].active : true;
          console.log(participant)
          if (!isActive) {
            return <s>{value}</s>; // Renders crossed-out text for inactive "imie" or "nazwisko" columns
          }
          else
          {
            return <p>{value}</p>
          }
        },
      },
    },
    {
      name: 'nazwisko',
      label: 'Nazwisko',
      options: {
        customBodyRender: (value, tableMeta) => {
          const participant = data[tableMeta.rowIndex];
          const isActive = data && data[tableMeta.rowIndex].active !== undefined ? data[tableMeta.rowIndex].active : true;
          console.log(participant)
          if (!isActive) {
            return <s>{value}</s>; // Renders crossed-out text for inactive "imie" or "nazwisko" columns
          }
          else
          {
            return <p>{value}</p>
          }
        },
      },
    },
    {
      name: 'obecnosc',
      label: 'Obecność',
      options: {
        customBodyRender: (value, tableMeta) => {
          const participant = Uczestnicy[tableMeta.rowIndex];
          const isActive = participant && participant.active !== undefined ? participant.active : true;
          console.log(participant)
          return (
            <input
              className="green-checkbox"
              type="checkbox"
              checked={value}
              onChange={() => isActive && handleObecnosc(tableMeta.rowIndex)}
              disabled={!isActive}
            />
          );
        },
      },
    },
    {
      name: 'spozniony',
      label: 'Spóźniony',
      options: {
        customBodyRender: (value, tableMeta) => {
          const participant = Uczestnicy[tableMeta.rowIndex];
          const isActive = participant && participant.active !== undefined ? participant.active : true;
          return (
            <input
              className="green-checkbox"
              type="checkbox"
              checked={value}
              onChange={() => isActive && handleSpozniony(tableMeta.rowIndex)}
              disabled={!isActive}
            />
          );
        },
      },
    },
    {
      name: 'odrabianie',
      label: 'Odrabianie',
      options: {
        customBodyRender: (value, tableMeta) => {
          const participant = Uczestnicy[tableMeta.rowIndex];
          const isActive = participant && participant.active !== undefined ? participant.active : true;
          return (
            <input
              className="green-checkbox"
              type="checkbox"
              checked={value}
              onChange={() => isActive && handleOdrabianie(tableMeta.rowIndex)}
              disabled={!isActive}
            />
          );
        },
      },
    },
    {
      name: 'uwagi',
      label: 'Uwagi',
      options: {
        customBodyRender: (value, tableMeta) => {
          const participant = Uczestnicy[tableMeta.rowIndex];
          const isActive = participant && participant.active !== undefined ? participant.active : true;
          return (
            <input
              className="green-checkbox"
              type="text"
              value={value}
              onChange={(e) => isActive && handleUwagi(tableMeta.rowIndex, e.target.value)}
              onBlur={(e) => isActive && handleUwagi(tableMeta.rowIndex, e.target.value)}
              disabled={!isActive}
            />
          );
        },
      },
    },
  ];


  const sortedParticipants = Uczestnicy.slice().sort((a, b) => {
    const [aLastName, bLastName] = [
      a.name.split(' ').pop(),
      b.name.split(' ').pop(),
    ];
    return aLastName.localeCompare(bLastName);
  });

  const data = sortedParticipants.map((participant, index) => {
    const nameParts = participant.name.split(' ');
    const imie = nameParts[0];
    const nazwisko = nameParts.slice(1).join(' ');
   
    return {
      active: participant.active,
      id: participant.id,
      correctIndex: index + 1,
      imie: imie,
      nazwisko: nazwisko,
      obecnosc: participant.obecnosc,
      spozniony: participant.spozniony,
      odrabianie: participant.odrabianie,
      uwagi: participant.uwagi,
      originalIndex: Uczestnicy.findIndex((p) => p.id === participant.id),
    };
    
  });
  console.log(data)
  const options = {
    textLabels: {


      body: {
        noMatch: "Nie ma żadnych uczestników :(",

      },
    pagination: {
      next: "Następna strona",
      previous: "Poprzednia strona",
      rowsPerPage: "",
      displayRows: "z" // 1-10 of 30
    },
    toolbar: {
      search: "Szukaj",
      downloadCsv: "Pobierz CSV",
      print: "Drukuj",
      viewColumns: "Zobacz kolumny",
      filterTable: "Filtruj tabelę"
    },
    filter: {
      title: "FILTRY",
      reset: "reset",          
    },
    viewColumns: {
      title: "Pokaż kolumny"
    },
    selectedRows: {
      text: "wierszy usuniętych",
      delete: "Usuń"
    }
},
    selectableRows: 'none',
    filter: false,
    download: false,
    print: false,
    sort:false,
    rowsPerPage: data.length, // Set the number of rows per page
    page: 0, // Display the first page
  };

  columns.forEach((col) => {
    if (col.name === 'obecnosc' || col.name === 'spozniony' || col.name === 'odrabianie' || col.name === 'uwagi') {
      col.options = {
        ...col.options,
        customBodyRender: (value, tableMeta) => {
          return col.name === 'uwagi' ? (
            <input
            className="green-textbox"
              type="text"
              value={value}
              onChange={(e) => handleUwagi(data[tableMeta.rowIndex].originalIndex, e.target.value)}
              onBlur={(e) => handleUwagi(data[tableMeta.rowIndex].originalIndex, e.target.value)}
              disabled={!data[tableMeta.rowIndex].active}
            />
          ) : (
            <input
            className="green-checkbox"
              type="checkbox"
              checked={value}
              onChange={() => {
                if (col.name === 'obecnosc') handleObecnosc(data[tableMeta.rowIndex].originalIndex);
                else if (col.name === 'spozniony') handleSpozniony(data[tableMeta.rowIndex].originalIndex);
                else if (col.name === 'odrabianie') handleOdrabianie(data[tableMeta.rowIndex].originalIndex);
              }}
              disabled={!data[tableMeta.rowIndex].active}
            />
          );
        },
      };
    }
  });

  return (
    <MUIDataTable
      title="Uczestnicy"
      data={data}
      columns={columns}
      options={options}
    />
  );
};

const DateGrabber = (props) => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <DatePicker
      highlightDates={props.dates}
      id="date-picker"
      containerStyle={{ width: '100%' }}
      wrapperClassName="datepicker"
      className="datepicker"
      locale="pl"
      dateFormat="dd/MM/yyyy"
      ref={props.dvalue}
      defaultValue={props.selectedDate}
      selected={props.selectedDate}
      onChange={(date) => {
        console.log(date);
        if (props.setSelectedDate && typeof props.setSelectedDate === 'function') {
          props.setSelectedDate(date); // Call setSelectedDate as a function if it's provided
        }
      }}
    />
  );
};




const SkeletonComponent = () => {
  return (
    <>
      <p style={{ textAlign: "center" }}>
        <Skeleton height={20} width={100} />
      </p>
      <h1 style={{ color: "green", fontFamily: "'Lato', sans-serif", textAlign: "center" }}>
        <Skeleton height={40} width={200} />
      </h1>
      {/* Other components with skeleton loading */}
      <div style={{ textAlign: "center" }}>
        <Skeleton count={10} height={20} style={{ marginBottom: '10px' }} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {/* You can generate skeleton elements based on your actual structure */}
        <div style={{ width: '50%' }}>
          <Skeleton height={200} />
        </div>
        <div style={{ width: '50%' }}>
          <Skeleton height={200} />
        </div>
      </div>
    </>
  );
};

export default function Group() {
  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const [loading, setLoading] = useState(true);
  const groupId = pathname.split("/").pop();
  const [isSpinning, setIsSpinning] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupUsers, setGroupUsers] = useState([]);
  const [allUsers, setAllUsers] = useState({});
  const [matchedUsersInfo, setMatchedUsersInfo] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const wczytajButtonRef = useRef(null);
  const [wczytajClicked, setWczytajClicked] = useState(false);
  const { setIsOpen } = useTour()

  const startAnimation = () => {
    setIsSpinning(true);
    // Perform the action you want here
    // For example, API request, data processing, etc.
    // Set isSpinning back to false when the action is complete
    setTimeout(() => {
      setIsSpinning(false);
    }, 2000); // Replace this with your action's completion
  };

  // Function to reset the state
  const resetWczytajClicked = () => {
    setWczytajClicked(false);
  };

  useEffect(() => {
    // This effect will trigger whenever the `pathname` changes
    resetWczytajClicked();
  }, [pathname]);
  
  


  useEffect(() => {
    // Fetch group information based on the provided group ID
    fetchGroupData(groupId);
    
    const isoDate = new Date(selectedDate.getTime() - (selectedDate.getTimezoneOffset() * 60000)).toISOString();
    Retrieve(isoDate.split('T')[0])
    
  }, [groupId]);

  const fetchGroupData = async (groupId) => {
    var idCounter = 0;
    try {
      const response = await axios.get(`https://mobileappscrapper.herokuapp.com/Eko_get_all_groups`);
      if (response.data && response.data[groupId]) {
        const groupData = JSON.parse(response.data[groupId]);
        setGroupName(groupData.name);
        setGroupUsers(groupData.users);
        
        const uczestnicyArray = groupData.users.map((userId) => {
          const matchedUser = allUsers[userId];
          if (matchedUser) {
            const userObject = {
              id: idCounter, // Use the idCounter as id
              internalId: matchedUser.id,
              name: `${matchedUser.first_name} ${matchedUser.last_name}`,
              active: true,
              obecnosc: false,
              spozniony: false,
              odrabianie: false,
              uwagi: "",
            };
            idCounter++; // Increment the counter only for matched users
            return userObject;
          }
          return {
            id: idCounter, // Use the idCounter as id
            name: userId,
            active: true,
            obecnosc: false,
            spozniony: false,
            odrabianie: false,
            uwagi: "",
          };
        });
  
        setUczestnicy(uczestnicyArray);
        fetchAllUsers();
      } else {
        console.error("Group data not found for ID:", groupId);
      }
      setLoading(false);
      setDataLoaded(true);
    } catch (error) {
      console.error("Error fetching group data:", error);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const response = await axios.get(`https://ekoland-dziennik.pl/readStudents.php`);
      setAllUsers(response.data);
      setLoading(false)
      setDataLoaded(true);
    } catch (error) {
      console.error("Error fetching all users:", error);
    }
  };

  useEffect(() => {
    // Match groupUsers with allUsers and save the matched information
    const matchedInfo = groupUsers.map((userId) => {
      const matchedUser = allUsers[userId];
      if (matchedUser) {
        return {
          id: matchedUser.id, // Use the user's actual id
          name: `${matchedUser.first_name} ${matchedUser.last_name}`,
          obecnosc: false,
          spozniony: false,
          odrabianie: false,
          uwagi: "",
        };
      }
      return null;
    });
  
    setMatchedUsersInfo(matchedInfo.filter((user) => user !== null));
    setLoading(false)
  }, [groupUsers, allUsers]);


  registerLocale("pl", pl);
  const Temat = useRef([]);
  const Grupa = groupName;
  const dvalue = useRef("");
  const nameRef = useRef("");
  const disabler = useRef("");
  const info = useRef("");
  const podpis = useRef("");
  const warning = useRef("");
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  var [moreButtonRef, setMoreButtonRef] = useState(null);
  var [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
  var lucz = 0;
  const [liczbaUczestnikow, setLiczbaUczestnikow] = useState(0)
  const [Dates, setDates] = useState([]);
  const [Uczestnicy, setUczestnicy] = useState([]);
  const [UczestnicyBase, setUczestnicyBase] = useState([]);
  
  const notifySuccess = (text) => {
    if (!toastShown) {
      toast.success(text, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
    setToastShown(true);
  }}
  const notifyError = (text) => {
    if (!toastShown) {
      toast.error(text, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setToastShown(true);
  }}

  useEffect(()=>{

    Highlight(groupId);
  }, [])


  
  const GetBase = async () => {
    try {
      setZaj([]);
  
      const response = await axios.get(`https://ekoland-dziennik.pl/readStudents.php`);
      const allUsers = response.data;
  
      const newUczestnicy = groupUsers.map((userId, index) => {
        const matchedUser = allUsers[userId];
        if (matchedUser) {
          return {
            id: index, // Use the index as id
            internalId: matchedUser.id,
            name: `${matchedUser.first_name} ${matchedUser.last_name}`,
            active: matchedUser.active, // Assuming all users are active in this context
            obecnosc: false,
            spozniony: false,
            odrabianie: false,
            uwagi: "",
            pesel: matchedUser.pesel
          };
        }
        return null;
      });
  
      setUczestnicy(newUczestnicy.filter((user) => user !== null));
      setUczestnicyBase(newUczestnicy.filter((user) => user !== null));
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  
  useEffect(() => {
    setZaj([])
    
    odswiezUczestnikow();
    setView();
    
  }, [Uczestnicy, liczbaUczestnikow]);



 
 
function odswiezUczestnikow()
{lucz = 0;
  for(let element in Uczestnicy)
  {
    
    if(Uczestnicy[element].obecnosc == true || Uczestnicy[element].spozniony == true || Uczestnicy[element].odrabianie == true)
    {
      lucz++;
      setLiczbaUczestnikow(lucz);
    }
    if(lucz == 0)
    {
      setLiczbaUczestnikow(0);
    }
  }
}

function UczestnikComponent(props) {
  const [imie, setImie] = useState(props.name);
  const [id, setId] = useState(props.id);
  const [obecnosc, setObecnosc] = useState(props.obecnosc);
  const [spozniony, setSpozniony] = useState(props.spozniony);
  const [odrabianie, setOdrabianie] = useState(props.odrabianie);
  const [uwagi, setUwagi] = useState(props.uwagi);
  const [active, setActive] = useState(props.active);

  const handleObecnosc = (id) => {
    const updatedUczestnicy = [...Uczestnicy];
    updatedUczestnicy[id].obecnosc = !updatedUczestnicy[id].obecnosc;
    if (updatedUczestnicy[id].obecnosc) {
      //updatedUczestnicy[id].odrabianie = false;
    }
    setUczestnicy(updatedUczestnicy);
    
  };

  const handleSpozniony = (id) => {
    const updatedUczestnicy = [...Uczestnicy];
    updatedUczestnicy[id].spozniony = !updatedUczestnicy[id].spozniony;
    setUczestnicy(updatedUczestnicy);
    
  };

  const handleOdrabianie = (id) => {
    const updatedUczestnicy = [...Uczestnicy];
    updatedUczestnicy[id].odrabianie = !updatedUczestnicy[id].odrabianie;
    if (updatedUczestnicy[id].odrabianie) {
      //updatedUczestnicy[id].obecnosc = false;
    }
    setUczestnicy(updatedUczestnicy);
    
  };

  const handleUwagi = (id, newUwagi) => {
    const updatedUczestnicy = [...Uczestnicy];
    updatedUczestnicy[id].uwagi = newUwagi;
    setUczestnicy(updatedUczestnicy);
    
  };
  return (
  <>
  
  <Grid container spacing={2}>
  <Grid item><Typography>{props.id+1}</Typography></Grid>
  <Grid item xs={2}><Typography style={{ textDecoration: props.active ? 'none' : 'line-through' }}>{props.name}</Typography></Grid>
  <Grid item xs><Checkbox disabled={!active} defaultChecked={Uczestnicy[props.id]?.obecnosc} onChange={() => {handleObecnosc(props.id)}}></Checkbox></Grid>
  <Grid item xs><Checkbox disabled={!active} defaultChecked={Uczestnicy[props.id]?.spozniony} onChange={() => {handleSpozniony(props.id)}}></Checkbox></Grid>
  <Grid item xs><Checkbox disabled={!active} defaultChecked={Uczestnicy[props.id]?.odrabianie} onChange={() => {handleOdrabianie(props.id)}}></Checkbox></Grid>
  <Grid item xs={4}>
    <TextField
      disabled={!active}
      multiline
      style={{ width: "100%" }}
      defaultValue={Uczestnicy[props.id]?.uwagi}
      onChange={(e) => {
        setUwagi(e.target.value);
      }}
      onBlur={(e) => {
        handleUwagi(props.id, uwagi);
      }}
    />
  </Grid>
</Grid>

  </>
  );
}

const DodajUczestnikaModal = ({ allUsers, DodajUczestnika }) => {
  const [name, setName] = useState('');
  const [active, setActive] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const handleUserChange = (event) => {
    setSelectedUserId(event.target.value);
  
    // Update the 'name' state when a user is selected
    const selectedUser = allUsers[event.target.value];
    if (selectedUser) {
      // Check if selectedUser is defined before accessing its properties
      setName(`${selectedUser.first_name} ${selectedUser.last_name}`);
    } else {
      setName(''); // Clear the name if no user is selected
    }
  };

  const handleAddUser = () => {
    // You can use selectedUserId and active to add the selected user with the active status.
    // Execute the DodajUczestnika function with name and active
    if(!selectedUserId)
    {
      return;
    }
    DodajUczestnika(name, active, selectedUserId);

    // Reset the form
    setSelectedUserId('');
    setActive(true);
    setClicked(false);
  };

  return (
    <>

      {clicked ? (
        <>

        <Button
        
        style={{ marginLeft: '10px', color:"white", marginTop:"20px" }}
        variant="contained"
        color="primary"
        startIcon={<Remove></Remove>}
        onClick={() => {
          setClicked(false);
        }}
      >
       Anuluj
      </Button>
      <div>
      <input
  type="text"
  placeholder="Szukaj uczestnika"
  onChange={(e) => setSearchTerm(e.target.value)}
  style={{ height: "40px", fontSize: "18px" }}
/>
          <div style={{ maxHeight: '110px', overflowY: 'auto' }}> {/* Set max height and enable vertical scrolling */}
            <select
              value={selectedUserId}
              onChange={handleUserChange}
              size={5} // Set the visible options to 5
              style={{width:"100%"}}
            >
              <option value="">Wybierz uczestnika</option>
              {Object.keys(allUsers)
                .filter(userId => !Uczestnicy.find(user => user.internalId === userId))
                .filter(userId => {
                  const user = allUsers[userId];
                  const fullName = `${user.first_name} ${user.last_name} ${user.pesel}`;
                  return fullName.toLowerCase().includes(searchTerm.toLowerCase());
                })
                .map(userId => ({
                  id: userId,
                  last_name: allUsers[userId].last_name,
                  fullName: `${allUsers[userId].first_name} ${allUsers[userId].last_name} ${allUsers[userId].pesel}`
                }))
                .sort((a, b) => a.last_name.localeCompare(b.last_name))
                .map(user => (
                  <option key={user.id} value={user.id}>
                    {user.fullName}
                  </option>
                ))}
            </select>
          </div>
          </div>
          <div>
            <Button variant="contained" color="secondary" onClick={handleAddUser}>
              Dodaj
            </Button>
          </div>
        </>
      ) :  <Button
        style={{ marginLeft: '10px', color:"white", marginTop:"20px" }}
        variant="contained"
        color="primary"
        startIcon={<Add></Add>}
        className="pietnascie-step"
        onClick={() => {
          setClicked(true);
        }}
      >
        Dodaj uczestnika
      </Button>}
    </>
  );

}
 /*
 useEffect(()=>
 {
  Retrieve();
  for(let el in Uczestnicy)
  {
    setZaj(zaj => [...zaj,<UczestnikComponent id={Uczestnicy[el].id} obecnosc={Uczestnicy[el].obecnosc} spozniony = {Uczestnicy[el].spozniony} odrabianie ={Uczestnicy[el].odrabianie} uwagi="" key={Math.random()} name={Uczestnicy[el].name}></UczestnikComponent>])
  }
  setView();
 },[]);

*/

  const [zaj, setZaj] = useState([]);

 
  const classes = useStyles();
  var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;
var ReadyToSend;

const DodajUczestnika = (name, active, internalId) =>
{

    Uczestnicy.push({active: active, id:Uczestnicy.length,internalId: internalId, name:name,obecnosc:false,spozniony:false,odrabianie:false,uwagi:""})
    setZaj(zaj => [...zaj,<UczestnikComponent active={active} id={Uczestnicy.length} obecnosc={false} spozniony = {false} odrabianie ={false} uwagi="" key={Math.random()} name={name}></UczestnikComponent>])
    
    notifySuccess("Uczestnik dodany!")
    const updatedUczestnicy = [...Uczestnicy];
    setUczestnicy(updatedUczestnicy)
    setToastShown(false)
}

function Send() {
  if (podpis.current.value == "" || podpis.current.value == undefined || podpis.current.value == null) {
    warning.current.innerText = "Podpis prowadzącego jest pusty!";
    notifyError("Podpis prowadzącego jest pusty!");
    setToastShown(false)
  } else {
    if (Temat.current.value == "" || Temat.current.value == undefined || Temat.current.value == null) {
      warning.current.innerText = "Temat jest pusty!";
      notifyError("Temat jest pusty!");
      setToastShown(false)
    } else {
      warning.current.innerText = "";
      disabler.current.style.pointerEvents = "none";
      info.current.innerText = "Jesteś w trybie poglądu, kliknij przycisk Edytuj aby edytować";
      var dateToSend = new Date(selectedDate.getTime() - (selectedDate.getTimezoneOffset() * 60000)).toISOString();

      var ReadyToSend = {
        "Temat": Temat.current.value,
        "Grupa": groupId,
        "Data": dateToSend.split('T')[0],
        "Uczestnicy": Uczestnicy,
        "Podpis": podpis.current.value
      };

      // Create a fetch request to the specified endpoint using the POST method
      fetch('https://mobileappscrapper.herokuapp.com/create_entry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(ReadyToSend)
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        notifySuccess("Pomyślnie zapisano!");
        setToastShown(false)
      })
      .catch(error => {
        console.error('Error:', error);
        notifyError("Wystąpił błąd podczas zapisywania.");
        setToastShown(false)
      });
    }
  }
}
function setView() {
  /*
  setZaj([]);
  setZaj([]);
  setZaj([]);
  
  Uczestnicy.forEach((participant, index) => {
    if (participant.active === undefined) {
      participant.active = true;
    }
    
    setZaj(zaj => [
      ...zaj,
      <UczestnikComponent
        active={participant.active}
        id={index} // Set the ID to the index
        obecnosc={participant.obecnosc}
        spozniony={participant.spozniony}
        odrabianie={participant.odrabianie}
        uwagi=""
        key={Math.random()}
        name={participant.name}
      ></UczestnikComponent>
    ]);
  });
*/
  
}
  function Resetuj()
  {
/*
    setZaj([])
    for(let element in Uczestnicy)
  {
    Uczestnicy[element] = UczestnicyBase[element]
  }

    for(let el in Uczestnicy)
    {
      
      setZaj(zaj => [...zaj,<UczestnikComponent id={Uczestnicy[el].id} obecnosc={Uczestnicy[el].obecnosc} spozniony = {Uczestnicy[el].spozniony} odrabianie ={Uczestnicy[el].odrabianie} uwagi="" key={Math.random()} name={Uczestnicy[el].name}></UczestnikComponent>])
    }
    */
    setZaj([])
    GetBase()
    setView();
    odswiezUczestnikow();
    Temat.current.value = "";
    podpis.current.value = "";
    notifySuccess("Pomyślnie zresetowano!")
    setToastShown(false)
  }
 
  async function Retrieve(date) {
    try {
      const response = await axios.get('https://mobileappscrapper.herokuapp.com/get_global_lessons');
      console.log('Response data:', response.data); // Log the response data
  
      if (response.data && response.data.entries) {
        const entries = response.data.entries;
        console.log('Grupa:', Grupa);
        console.log('Date:', date);
        
        const matchingEntry = entries.find(entry => entry.Grupa === groupId && entry.Data.split('T')[0] === date);
        
        console.log('Matching Entry:', matchingEntry);
        console.log('Matching entry:', matchingEntry); // Log the matching entry
        
        if (matchingEntry) {
          setZaj([]);
          setView();
          setUczestnicy(matchingEntry.Uczestnicy);
  
          Temat.current.value = matchingEntry.Temat;
          podpis.current.value = matchingEntry.Podpis;
  
          odswiezUczestnikow();

          setToastShown(false)
        } else {
          setZaj([]);
          GetBase();
          Temat.current.value = "";
          podpis.current.value = "";
          odswiezUczestnikow();
        }
      } else {
        console.error('Invalid response data:', response.data);
        GetBase();
        Temat.current.value = "";
        podpis.current.value = "";
        odswiezUczestnikow();
      }
    } catch (error) {
      console.error('Error:', error);
      GetBase();
      Temat.current.value = "";
      podpis.current.value = "";
      odswiezUczestnikow();
    }
  }
  const Highlight = (groupId) => {
    // Define the groupId variable or fetch it from somewhere.
    
  
    axios
      .get('https://mobileappscrapper.herokuapp.com/get_global_lessons')
      .then(res => {
        const entries = res.data.entries;
  
        const highlightedDates = entries
          .filter(entry => entry.Grupa === groupId)
          .flatMap(entry => {
            try {
              const dateParts = entry.Data.split('-'); // Split by hyphens
              const year = dateParts[0];
              const month = dateParts[1];
              const day = dateParts[2].split('T')[0]; // Remove time part if present
              const formattedDate = `${day}/${month}/${year}`; // Convert to DD/MM/YYYY format
              console.log(formattedDate)
              return formattedDate;
            } catch (error) {
              console.error('Error parsing date:', error);
              return null;
            }
          })
          .filter(date => date !== null);
          console.log(highlightedDates)
        setDates(highlightedDates);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  useEffect(() => {
    const isoDate = new Date(selectedDate.getTime() - (selectedDate.getTimezoneOffset() * 60000)).toISOString();
    Retrieve(isoDate.split('T')[0]);
    odswiezUczestnikow();
  }, [selectedDate]);
  

  function handleObecnosc(dataIndex) {
    const updatedUczestnicy = [...Uczestnicy];
    updatedUczestnicy[dataIndex].obecnosc = !updatedUczestnicy[dataIndex].obecnosc;
    if (updatedUczestnicy[dataIndex].obecnosc) {
      updatedUczestnicy[dataIndex].odrabianie = false;
    }
    setUczestnicy(updatedUczestnicy);
    
  }
  
  function handleSpozniony(dataIndex) {
    const updatedUczestnicy = [...Uczestnicy];
    updatedUczestnicy[dataIndex].spozniony = !updatedUczestnicy[dataIndex].spozniony;
    setUczestnicy(updatedUczestnicy);
    
  }
  
  function handleOdrabianie(dataIndex) {
    const updatedUczestnicy = [...Uczestnicy];
    updatedUczestnicy[dataIndex].odrabianie = !updatedUczestnicy[dataIndex].odrabianie;
    if (updatedUczestnicy[dataIndex].odrabianie) {
      //updatedUczestnicy[dataIndex].obecnosc = false;
    }
    setUczestnicy(updatedUczestnicy);
    
  }
  
  function handleUwagi(dataIndex, newUwagi) {
    const updatedUczestnicy = [...Uczestnicy];
    updatedUczestnicy[dataIndex].uwagi = newUwagi;
    setUczestnicy(updatedUczestnicy);
    
  }

  const DeleteLessonButton = (props) => {
    const [data, setData] = useState(props.data); // State for Date of the lesson
    const [grupa, setGrupa] = useState(props.grupa); // State for Group Id
  
    const handleDeleteLesson = () => {
      // Create a JSON object with the parameters
      const requestBody = {
        Data: data,
        Grupa: grupa,
      };
  
      // Send a DELETE request to the server
      fetch('https://mobileappscrapper.herokuapp.com/delete_lessons', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          if (response.ok) {
            // Lesson deleted successfully, you can handle this as needed
            console.log('Lesson deleted successfully');
            window.location.reload();
          } else {
            // Handle errors or validation failures here
            console.error('Failed to delete lesson');
          }
        })
        .catch((error) => {
          console.error('Error deleting lesson:', error);
        });
    };
  
    return (

<Button className="fifth-step" startIcon={<DeleteOutlined></DeleteOutlined>} style={{color:"white", marginLeft:"10px", marginBottom:"10px", backgroundColor:"red"}}variant="contained" color="error" onClick={handleDeleteLesson}>Usuń Lekcję</Button>

       
     
    );
  };

  const handleWczytajClicked = () => 
  {
    disabler.current.style.pointerEvents = "initial";
    setWczytajClicked(true);
  
  }
  return (
    <>
    <div style={{textAlign:"center"}}>
      {wczytajClicked ? (<></>) : (<>
      

      
      <Button startIcon={<Sync
        style={{
          animation: isSpinning
            ? 'spin 1s linear infinite'
            : 'none',
          transformOrigin: 'center',
        }}
      />} ref={wczytajButtonRef} id="WczytajButton" style={{color:"white", marginLeft:"10px", padding:"2%",marginBottom:"10px", width:"50%"}}variant="contained" color="primary" onClick={() => {
   const isoDate = new Date(selectedDate.getTime() - (selectedDate.getTimezoneOffset() * 60000)).toISOString();
   Retrieve(isoDate.split('T')[0]);
   handleWczytajClicked();
   startAnimation();
  }}>Wczytaj dane</Button></>)}
    
  {wczytajClicked ? (<></>) : (<><h3 style={{color:"red"}}>Proszę najpierw wczytać dane!</h3><SkeletonComponent></SkeletonComponent></>)}
    </div>
    
      <Grid style={{ visibility: wczytajClicked ? "initial" : "hidden", pointerEvents: wczytajClicked ? "initial" : "none"}}>

      <Button variant="contained" style={{color:"White"}} color="primary" onClick={() => setIsOpen(true)}>Jak sprawdzić obecność?</Button>
        <div>

    </div>
    <p style={{textAlign:"center"}}>Grupa</p>
    <h1 title={groupName} className="first-step" style={{ color: "green", fontFamily: "'Lato', sans-serif", textAlign:"center" }}>{groupName}</h1>
      
      
      <Widget>
      

        <FormControl className="second-step">
        <label style={{textAlign:"center"}} htmlFor="date-picker">Wybierz datę</label>
       
        <DateGrabber  dates={Dates.map((dateString) => parse(dateString, 'dd/MM/yyyy', new Date()))}
    id="date-picker"
    containerStyle={{width: '100%'}}
    wrapperClassName="datepicker"
    selectedDate={selectedDate}
  setSelectedDate={setSelectedDate}
    className="datepicker"
    dateFormat='dd/MM/yyyy' 
    todayButton={"Dzisiaj"}
    locale="pl"
    fixedHeight
    label="Lekcja"
    popperPlacement="bottom"
    highlightDates={['Sun Sep 09 2023 16:27:51 GMT+0200 (czas środkowoeuropejski letni)']}
    placeholderText="This highlights a week ago and a week from today"
    ref={dvalue}
    defaultValue={selectedDate}
    selected={selectedDate}
    onChange={(date) => {
      console.log(date)
      Highlight(groupId);
      setSelectedDate(date);
      const isoDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
      Retrieve(isoDate.split('T')[0]);
    }}
/>
        </FormControl>
<br></br>  
<br></br>

<Button className="third-step" startIcon={<Sync
        style={{
          animation: isSpinning
            ? 'spin 1s linear infinite'
            : 'none',
          transformOrigin: 'center',
        }}
      />} ref={wczytajButtonRef} id="WczytajButton" style={{color:"white", marginLeft:"10px",marginBottom:"10px"}}variant="contained" color="primary" onClick={() => {
   const isoDate = new Date(selectedDate.getTime() - (selectedDate.getTimezoneOffset() * 60000)).toISOString();
   Retrieve(isoDate.split('T')[0]);
   handleWczytajClicked();
   startAnimation();
  }}>Wczytaj dane</Button>
  <Button className="fourth-step" startIcon= {<Backspace></Backspace>} style={{color:"white", marginLeft:"10px", marginBottom:"10px"}}variant="contained" color="primary" onClick={()=> {Resetuj()}}>Resetuj lekcje</Button> 
      <DeleteLessonButton  grupa={groupId} data={()=>{
const isoDate = new Date(selectedDate.getTime() - (selectedDate.getTimezoneOffset() * 60000)).toISOString();
return isoDate.split('T')[0]
      }}>Usuń</DeleteLessonButton>
      
      
      
      
      <Grid container spacing={4}>
        <Grid item xs={12} style={{textAlign:"center"}}>
          <Typography className="six-step" ><b>Temat zajęć</b></Typography>
        <TextField inputProps={{min: 0, style: { textAlign: 'center' }}} style={{textAlign:"center",justifyContent:"center"}} fullWidth inputRef={Temat} InputLabelProps={{ shrink: true }}  label=""></TextField>
        <Typography className="seven-step">Liczba uczestników: {liczbaUczestnikow}</Typography>
        <br></br>
        <br></br>
        
       
        
         
     


      
        
        </Grid>
        
        </Grid>
        
        <Grid item xs={1}>
        
<IconButton
                  color="primary"
                  
                  classes={{ root: classes.moreButton }}
                  aria-owns="widget-menu"
                  aria-haspopup="true"
                  onClick={() => setMoreMenuOpen(true)}
                  ref={setMoreButtonRef}
                ><MenuButton style={{marginLeft:"-20px"}}></MenuButton></IconButton> <Menu
        id="widget-menu"
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
      >
        <MenuItem>
          <Typography onClick={()=>{for(let element in Uczestnicy){Uczestnicy[element].obecnosc = Uczestnicy[element].obecnosc = true}setView();setMoreMenuOpen(false)}}>Wszyscy obecni</Typography>
        </MenuItem>
        <MenuItem>
          <Typography onClick={()=>{disabler.current.style.pointerEvents = "initial";info.current.innerText = "Jesteś w trybie poglądu, kliknij przycisk Edytuj aby edytować";setMoreMenuOpen(false)}}>Edytuj</Typography>
        </MenuItem>
      </Menu>
      
</Grid>
<Typography style={{textAlign:"center", color:"primary"}} ref={info}></Typography>
<div className="ten-step eight-step nine-step twelve-step eleven-step trzynascie-step czternascie-step" ref={disabler} style={{pointerEvents:"none"}}>
  <Grid container spacing={1}>
    <Grid  item>
      <b></b>
    </Grid>
    <Grid  item xs={2}>
      <b></b>
    </Grid>
    <Grid
      item
      xs
      onClick={() => {
        for (let element in Uczestnicy) {
          Uczestnicy[element].obecnosc = !Uczestnicy[element].obecnosc;
        }
        setView(); // Moved setView() outside the loop
      }}
    >
      <b></b>
    </Grid>

  </Grid>


<br />
<br />
{/*}
{zaj}
    {*/}
    <DataTable Uczestnicy={Uczestnicy} handleObecnosc={handleObecnosc} handleSpozniony={handleSpozniony} handleOdrabianie={handleOdrabianie} handleUwagi={handleUwagi} active={true} ></DataTable>

<DodajUczestnikaModal  allUsers={allUsers} DodajUczestnika={DodajUczestnika}></DodajUczestnikaModal>
</div>

</Widget>


<Grid container spacing={4}>
  <Grid item xs={12}>
    <Widget>
      <TextField className="szesnascie-step" InputLabelProps={{ shrink: true }} inputRef={podpis} label="Podpis prowadzącego" /><br /><br />
      <Button className="siedemnascie-step" startIcon={<Save/>} style={{ color: "white" }} color="primary" variant="contained" onClick={() => { Send() }}>Zapisz</Button>
      <Button className="osiemnascie-step" startIcon= {<Edit/>} style={{ marginLeft: "10px" }} onClick={() => { disabler.current.style.pointerEvents = "initial"; info.current.innerText = "" }}>Edytuj</Button>
      <div ref={warning} style={{ color: "red" }}></div>
    </Widget>
    
  </Grid>
  
</Grid>
</Grid>


<ScrollToTopButton />

<ToastContainer
  position="top-center"
  autoClose={5000}
  hideProgressBar={false}
  newestOnTop={false}
  closeOnClick
  rtl={false}
  draggable
  theme="light"
/>

        

        
  
    

   
      </>
      
  );
  
}

