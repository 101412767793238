import React, { useEffect, useState, useRef } from "react";
import ReactFragment from "react"
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  IconButton,
  TableCell,
  Toolbar,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Hidden
} from "@material-ui/core";
import { ToastContainer, toast } from 'react-toastify';
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";
import { Delete } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { ArrowUpward, ArrowDownward, Save } from "@material-ui/icons";
import { Edit, Add } from "@material-ui/icons";
// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import PlanZajec1 from "./PlanZajec1.webp"
import PlanZajec2 from "./PlanZajec2.webp"
import PlanZajec3 from "./PlanZajec3.webp"
import PlanZajec4 from "./PlanZajec4.webp"
import axios from "axios";
import {Checkbox, TextField} from "@material-ui/core";


export default function AdminMenu(props) {
  var classes = useStyles();
  var theme = useTheme();
  
  const nameRef = useRef();
  const selectRef = useRef();
  const [activeness, setActiveness] = useState(true);
  const [temporaryIndex, setTemporaryIndex] = useState(0)
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editDialogValue, setEditDialogValue] = useState("");
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [grupa, setGrupa] = useState("");
  const updateUczestnicy = (newValue, rowIndex, active) => {
    const updatedUczestnicy = [...Uczestnicy];
    updatedUczestnicy[rowIndex].name = newValue;
    updatedUczestnicy[rowIndex].active = active;
    setUczestnicy(updatedUczestnicy);
    setEditDialogOpen(false);
    notifySuccess("Pomyślnie zmieniono uczestnika!")
  }

  const notifySuccess = (text) => toast.success(text, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
    const notifyError = (text) => toast.error(text, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  function EditDialog(props) {
    const { open, onClose, value, onSave, defaults} = props;
    const [newValue, setNewValue] = useState(value);
    const [active, setActive] = useState(false);
    const handleSave = () => {
      onSave(newValue, active);
    };
  
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edytuj uczestnika</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Imię i nazwisko"
            type="text"
            fullWidth
            value={newValue}
            onChange={event => setNewValue(event.target.value)}
          />
          
        </DialogContent>
        <FormGroup>
  <FormControlLabel control={<Checkbox defaultChecked={defaults} onChange={(e)=> {setActive(e.target.checked)}} />} label="Aktywny" />
</FormGroup>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Anuluj
          </Button>
          <Button onClick={(handleSave)} color="primary">
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const Grupy = [];
  const [Uczestnicy, setUczestnicy] = useState([])

  
//    {"id": 1, "name":"Damian Chymkowski", "active":true}, {"id": 2, "name": "Klaudia Baka", "active":true}]);


  
  function CreateUser(nameRef, activeness)
  {
    if(nameRef != "" & nameRef.length > 3)
    {
      Uczestnicy.push({
        id: Uczestnicy.length,
        name: nameRef,
        active: activeness
      })
      const updatedGrupy = [...Uczestnicy];
    setUczestnicy(updatedGrupy)
    notifySuccess("Pomyślnie dodano uczestnika: " + nameRef)
    }
    else
    {
      if(nameRef == "")
      {
        notifyError("Nazwa uczestnika jest pusta!")
        return;
      }
      if(nameRef.length <= 3)
      {
        notifyError("Imię i nazwisko uczestnika " + nameRef + " ma za mało znaków (min. 3)")
        return;
      }
    }
    
  }
  const uczestnicy = Uczestnicy
 

const columns = [{name: "LP"},{name: "Uczestnik"}, {name:"Aktywny"} ,{name:"Akcja", options: {
  
  customRowRender: (data, dataIndex) => {
    const isActive = data[1] === "Aktywny";  // Check the active property in the data array for the current row
    return {
      style: {
        "& td": {
          textDecoration: isActive ? "none" : "line-through"  // Set the text-decoration to line-through if isActive is false
        }
      }
    };
  },
  
  
  customBodyRender: (value, tableMeta, updateValue) => {
  // tableMeta.rowIndex is the index of the row
  // you can use this to customize the button for each row
  const isActive = value;



  const openEditDialog = () => {
    setTemporaryIndex(tableMeta.rowIndex)
    setEditDialogOpen(true);
    setEditDialogValue(Uczestnicy[tableMeta.rowIndex].name);
    
  };
  
  const setValue = (newValue) => {
    setEditDialogValue(newValue);
    notifySuccess("Pomyślnie zmieniono uczestnika!")
    setEditDialogOpen(false);
  };
  
  return (
<>

    <IconButton aria-label="edit" onClick={openEditDialog}>
      <Edit />
    </IconButton>
    <EditDialog open={editDialogOpen} value={editDialogValue} onClose={()=>setEditDialogOpen(false)} defaults={Uczestnicy[temporaryIndex].active} onSave={ (newValue, active) => {updateUczestnicy(newValue, temporaryIndex, active); console.log(temporaryIndex)}} />
    <IconButton aria-label="delete" onClick={() => {
      // delete the row from the grupy state
      const updatedGrupy = [...Uczestnicy];
      updatedGrupy.splice(tableMeta.rowIndex, 1);
      const updatedUczestnicy = updatedGrupy.map((uczestnik, index) => {
        uczestnik.id = index;
        return uczestnik;
      });
      setUczestnicy(updatedUczestnicy);
      notifySuccess("Pomyślnie usunięto uczestnika!")
    }}>
      <DeleteIcon />

    </IconButton>
    <IconButton onClick={()=>
      {
        if (tableMeta.rowIndex === 0) return;
        const newUczestnicy = [...Uczestnicy];
  const temp = newUczestnicy[tableMeta.rowIndex];
  newUczestnicy.splice(tableMeta.rowIndex, 1);
  newUczestnicy.splice(tableMeta.rowIndex - 1, 0, temp);

  for (let i = 0; i < newUczestnicy.length; i++) {
    newUczestnicy[i].id = i;
  }

  setUczestnicy(newUczestnicy);

      }}>
      <ArrowUpward></ArrowUpward>
    </IconButton>
    <IconButton onClick= {()=> {
         if (tableMeta.rowIndex === Uczestnicy.length - 1) return; 
        const newUczestnicy = [...Uczestnicy];
        const temp = newUczestnicy[tableMeta.rowIndex];
        newUczestnicy.splice(tableMeta.rowIndex, 1);
        newUczestnicy.splice(tableMeta.rowIndex + 1, 0, temp);
      
        for (let i = 0; i < newUczestnicy.length; i++) {
          newUczestnicy[i].id = i;
        }
      
        setUczestnicy(newUczestnicy);
      }}>
      <ArrowDownward ></ArrowDownward>
    </IconButton>
    </>
  );
}}}];

const data = Uczestnicy.map(uczestnik => [uczestnik.id, uczestnik.name, uczestnik.active ? "Aktywny" : "Nieaktywny", ""]);

const options = {
  selectableRows: "none",
  pagination: false,
  filter: false,
  isRowSelectable: false,
  
  download: false,
  print:false,
  search:false,
  rowHover:true,
  viewColumns:false,
  textLabels: {
    body: {
      noMatch: "Brak uczestników",
      toolTip: "Sortuj"
    },

    toolbar: {
      search: "Szukaj",
      downloadCsv: "Pobierz",
      
    }
    
  },
  customActions: [
    {
      icon: () => (
        <Tooltip title="Usuń wiersz">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
      onClick: (event, rowData) => {
        const dataIndex = rowData.dataIndex;
        Uczestnicy.splice(dataIndex, 1);
        setUczestnicy([...Uczestnicy]);
      }
    }
  ],
  customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
    const handleButtonClick = (e) =>
    {
      setIsAddOpen(!isAddOpen);
      console.log(isAddOpen)
    }
  
    
    return (
      <>
      <IconButton variant="contained" color="primary" onClick={(e) => handleButtonClick(e)}>
        <Add></Add>
        Nowy uczestnik
      </IconButton>
      <IconButton color="primary" variant="contained" style={{display:"flex", margin:0, overflow:"hidden"}} onClick={()=>{axios.get(`https://mobileappscrapper.herokuapp.com/setuczestnicy/set` + "_" + grupa + "_" + JSON.stringify(Uczestnicy))
.then(res => {
 const Response = res.data[0];
 notifySuccess("Zapisano użytkowników grupy")
})
.catch(function (error){ if(error.response) { notifyError("Wystąpił błąd!: \n" + error.response)}})


          }}><Save></Save>Zapisz</IconButton>
      </>
    );
    
  }


  };
  const handleChange = (event) =>
  {
   setGrupa(event.target.value)
   axios.get(`https://mobileappscrapper.herokuapp.com/setuczestnicy/get` + "_" + event.target.value + "_")
   .then(res => {
    const resp = res.data[0];
    if(resp !== undefined)
    {
      setUczestnicy(JSON.parse(resp.Uczestnicy))
    }
    else
    {
      
      setUczestnicy([])
    }
   })
   .then()
   .catch(function (error){ if(error.response) { setUczestnicy([]); console.log(error)}})

  }

  function regenerateIds() {
    const updatedUczestnicy = Uczestnicy.map((uczestnik, index) => {
      return {
        id: index,
        name: uczestnik.name,
        active: uczestnik.active
      };
    });
    // update the Uczestnicy array with the updatedUczestnicy array
    setUczestnicy(updatedUczestnicy);
  }
  return (
    <>
    <Grid container>
<PageTitle title="Ustaw Uczestników grupy"></PageTitle>
<Widget style={{width:"100%", display:"flex",alignItems:"center",justifyContent:"center"}}>
  <Grid item>
  <FormControl variant="standard" style={{alignItems:"center", display:"flex"}}>
    <Typography variant="h3" color="primary">Wybierz grupę</Typography>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label=""
    onChange={handleChange}
    value={grupa}
    inputRef={selectRef}
    placeholder="Kliknij, by wybrać grupę"
  >
    
     <MenuItem disabled value="">
            <b>Wybierz grupę</b>
          </MenuItem>
    <MenuItem value={"BaletKlasaIAIB"}><Typography variant="h4">Balet Klasa IA-IB</Typography></MenuItem>
    <MenuItem value={"BaletKlasaIAIBRytmika"}><Typography variant="h4">Balet Klasa IA-IB Rytmika</Typography></MenuItem>
    <MenuItem value={"BaletKlasaIIB"}><Typography variant="h4"> Balet Klasa IIB</Typography> </MenuItem>
    <MenuItem value={"BaletKlasaIIBRytmika"}><Typography variant="h4"> Balet Klasa IIB Rytmika</Typography> </MenuItem>
<MenuItem value={"BaletKlasaIIIAIVA"}><Typography variant="h4"> Balet Klasa IIIA-IVA</Typography> </MenuItem>
<MenuItem value={"BaletKlasaIIIAIVAWspolczesny"}><Typography variant="h4"> Balet Klasa IIIA-IVA Współczesny</Typography> </MenuItem>
<MenuItem value={"BaletKlasaIVBVA"}><Typography variant="h4"> Balet Klasa IVB-VA</Typography> </MenuItem>
<MenuItem value={"BaletKlasaIVBVAWspolczesny"}><Typography variant="h4"> Balet Klasa IVB-VA Współczesny</Typography> </MenuItem>
<MenuItem value={"BaletGrupaIA"}><Typography variant="h4"> Balet Grupa IA</Typography> </MenuItem>
<MenuItem value={"BaletGrupaIIA"}><Typography variant="h4"> Balet Grupa IIA</Typography> </MenuItem>
<MenuItem value={"BaletGrupaIB"}><Typography variant="h4"> Balet Grupa IB</Typography> </MenuItem>
<MenuItem value={"BaletGrupaIIBIIIB"}><Typography variant="h4"> Balet Grupa IIB-IIIB</Typography> </MenuItem>
<MenuItem value={"BaletGrupaWA"}><Typography variant="h4"> Balet Grupa WA</Typography> </MenuItem>
<MenuItem value={"Pointy"}><Typography variant="h4"> Pointy</Typography> </MenuItem>
<MenuItem value={"WspolczesnyII"}><Typography variant="h4"> Wspolczesny II</Typography> </MenuItem>
<MenuItem value={"WspolczesnyVI"}><Typography variant="h4"> Wspolczesny VI</Typography> </MenuItem>
<MenuItem value={"CapoeiraIA"}><Typography variant="h4"> Capoeira IA</Typography> </MenuItem>
<MenuItem value={"CapoeiraIIBIIIB"}><Typography variant="h4"> Capoeira IIB-IIIB</Typography> </MenuItem>
<MenuItem value={"CapoeiraIVB"}><Typography variant="h4"> Capoeira IVB</Typography> </MenuItem>
<MenuItem value={"CapoeiraVB"}><Typography variant="h4"> Capoeira VB</Typography> </MenuItem>
<MenuItem value={"CapoeiraVIB"}><Typography variant="h4"> Capoeira VIB</Typography> </MenuItem>
<MenuItem value={"CapoeiraWA"}><Typography variant="h4"> Capoeira WA</Typography> </MenuItem>
<MenuItem value={"HiphopIII"}><Typography variant="h4"> Hiphop I-II</Typography> </MenuItem>
<MenuItem value={"HiphopVI"}><Typography variant="h4"> Hiphop VI</Typography> </MenuItem>
<MenuItem value={"JazzI"}><Typography variant="h4"> Jazz I</Typography> </MenuItem>
<MenuItem value={"AkroI"}><Typography variant="h4"> Akro I</Typography> </MenuItem>
<MenuItem value={"AkroII"}><Typography variant="h4"> Akro II</Typography> </MenuItem>
<MenuItem value={"ZumbaI"}><Typography variant="h4"> Zumba I</Typography> </MenuItem>
<MenuItem value={"ZumbaII"}><Typography variant="h4"> Zumba II</Typography> </MenuItem>
<MenuItem value={"Zespol"}><Typography variant="h4"> Zespol</Typography> </MenuItem>
<MenuItem value={"ZespolJunior"}><Typography variant="h4"> Zespół Junior</Typography> </MenuItem>
<MenuItem value={"ZespolBaby"}><Typography variant="h4"> Zespół Baby</Typography> </MenuItem>
<MenuItem value={"PrzedszkoleMlodsze"}><Typography variant="h4"> Przedszkole Młodsze</Typography> </MenuItem>
<MenuItem value={"PrzedszkoleStarsze"}><Typography variant="h4"> Przedszkole Starsze</Typography> </MenuItem>
<MenuItem value={"FitnessPN"}><Typography variant="h4"> Fitness PN</Typography> </MenuItem>
<MenuItem value={"FitnessWT"}><Typography variant="h4"> Fitness WT</Typography> </MenuItem>
<MenuItem value={"FitnessSR"}><Typography variant="h4"> Fitness SR</Typography> </MenuItem>
<MenuItem value={"GrupaTestowa"}><Typography variant="h4">Grupa Testowa</Typography> </MenuItem>
  </Select>
</FormControl>
<MUIDataTable title="Grupy" data={data} columns={columns} options={options}/>
       <Grid>
{isAddOpen ? 
      <>
      <Grid container>
        <Widget style={{width:"100%", display:"flex",alignItems:"center",justifyContent:"center"}}>
        <Grid item xs >
        <TextField inputRef={nameRef} fullWidth placeholder="Imię i nazwisko"></TextField>
        <FormGroup>
  <FormControlLabel control={<Checkbox defaultChecked onChange={(e)=>{setActiveness(e.target.checked)}} />} label="Aktywny" />
</FormGroup>
<Button color="secondary" variant="outlined" onClick={()=> {CreateUser(nameRef.current.value, activeness); setIsAddOpen(false)}}>Dodaj uczestnika</Button>
</Grid>
        </Widget>
      
      </Grid>
      </> 
      : null

}

      </Grid>
         
          
  </Grid>
  </Widget>
</Grid>
{/*<Button onClick={()=>{regenerateIds()}}>Regenerate</Button>*/}
<ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>

    </>
    )
}
