import {React, useEffect, useRef, useState, useReducer} from "react";
import { Grid, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import {Checkbox, Typography} from "@material-ui/core";
import {Input} from "@material-ui/core";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { DataGrid, GridColDef, GridValueGetterParams} from '@material-ui/data-grid';
// data
import mock from "../dashboard/mock";
import { createTheme } from '@material-ui/core/styles'
import MenuButton  from "@material-ui/icons/Menu"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";
import {
  Menu,
  MenuItem,
  FormControlLabel,
  FormGroup,
  FormControl
} from "@material-ui/core";
import axios from 'axios';
import './styles.css';
import { ToastContainer, toast } from 'react-toastify';
import Oceny from "./Oceny";

import { Add as Save
} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },
  datepicker: {
    backgroundColor: theme.palette.primary.main,
  },

}))



export default function Wychowankowie() {
  const classes = useStyles();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pesel, setPesel] = useState("");
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [file, setFile] = useState(null);
  const [combinedData, setCombinedData] = useState([]);
  const [editedData, setEditedData] = useState([]);
  useEffect(() => {
    if (groups.length > 0 && users.length > 0) {
      const combinedUserData = users.flatMap(user => {
        const userGroups = groups.filter(group => group.users.includes(user.id));
        if (userGroups.length === 0) {
          // If user doesn't belong to any group, return a single entry for that user
          return {
            userFirstName: user.first_name,
            userLastName: user.last_name,
            pesel: user.pesel,
            groups: '',
            groupId: '',
            userId: user.id
          };
        } else {
          // If user belongs to multiple groups, create duplicate entries for each group
          return userGroups.map(group => ({
            userFirstName: user.first_name,
            userLastName: user.last_name,
            pesel: user.pesel,
            groups: group.name,
            groupId: group.id,
            userId: user.id
          }));
        }
      });
      setCombinedData(combinedUserData);
    }
  }, [groups, users]);

  useEffect(() => {
    // Initialize editedData with combinedData when it changes
    const updatedEditedData = combinedData.map(user => ({
      ...user,
      Ocena1: user.Ocena1 || null,
      Ocena2: user.Ocena2 || null,
      Ogolne: user.Ogolne || null,
      Notatki: user.Notatki || null,
      // Add other fields as needed
    }));
    setEditedData(updatedEditedData);
  }, [combinedData]);

  const fetchOcenyData = async () => {
    console.log(combinedData);
    try {
      const response = await axios.get("/read_oceny.php");
      const ocenyData = response.data;
      
     
        setCombinedData(prevData => {
          return prevData.map(user => {
            const matchingOceny = ocenyData.find(
              oceny => oceny.user_id === user.userId && oceny.group_Id === user.groupId
            );
    
            console.log('Users:', user);
            console.log('Matching Oceny:', matchingOceny);
    
            if (matchingOceny) {
              return {
                ...user,
                Ocena1: matchingOceny.ocena1 || '',
                Ocena2: matchingOceny.ocena2 || '',
                Ogolne: matchingOceny.ogolna || '',
                Notatki: matchingOceny.notatki || '',
              };
            }
            return user;
          });
        });
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania ocen!");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersResponse = axios.get("https://mobileappscrapper.herokuapp.com/Eko_get_all_users");
        const groupsResponse = axios.get("https://mobileappscrapper.herokuapp.com/Eko_get_all_groups");
  
        const [usersRes, groupsRes] = await Promise.all([usersResponse, groupsResponse]);
  
        const usersArray = Object.values(usersRes.data);
        setUsers(usersArray);
  
        const parsedGroups = Object.entries(groupsRes.data).map(([id, groupString]) => ({
          id,
          ...JSON.parse(groupString)
        }));
        setGroups(parsedGroups);
  
        // Now, once users and groups are fetched, proceed to fetch oceny data
        fetchOcenyData();
      } catch (error) {
        toast.error("Wystąpił błąd podczas wczytywania danych!");
      }
    };
  
    fetchData();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "https://mobileappscrapper.herokuapp.com/Eko_get_all_users"
      );
      const usersArray = Object.values(response.data); // Convert object properties to an array
      setUsers(usersArray);
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania uczestników");
    }
  };
console.log(users)
  useEffect(() => {
    
    fetchUsers();
    fetchGroups();
    fetchOcenyData();

  }, []);

 

 



  const fetchGroups = async () => {
    try {
      const response = await axios.get(
        "https://mobileappscrapper.herokuapp.com/Eko_get_all_groups"
      );

      // Parse JSON strings into objects
      const parsedGroups = Object.entries(response.data).map(([id, groupString]) => ({
        id,
        ...JSON.parse(groupString)
      }));
      setGroups(parsedGroups);
    } catch (error) {
      toast.error("Wystąpił błąd podczas wczytywania grup!");
    }
  };
  
  const writeOcenyData = async (data) => {
    try {
      const payload = {
        data // Include the oceny data array
      };
  
      const response = await axios.post(
        "/write_oceny.php",
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      console.log("Data written successfully:", response.data);
      // Optionally, handle the response from the server after writing the data
    } catch (error) {
      console.error("Error writing data:", error);
      toast.error("Wystąpił błąd podczas zapisywania ocen!");
    }
  };

  const handleWriteDataClick = () => {
    if (editedData.length > 0) {
      const dataToWrite = editedData.map(user => ({
        user_id: user.userId, // Use 'userId' instead of 'user_id'
        group_id: user.groupId, // Assuming 'groupId' exists in user data
        Ocena1: user.Ocena1 || null,
        Ocena2: user.Ocena2 || null,
        Ogolne: user.Ogolne || null,
        Notatki: user.Notatki || null,
        // ... (include other fields as needed)
      }));
  
      writeOcenyData(dataToWrite);
    } else {
      console.log("No data available to write.");
      // Handle this scenario as needed
    }
  };

  const findGroupName = (groupId) => {
    const foundGroup = groups.find(group => group.id === groupId);
    return foundGroup ? foundGroup.name : ''; // Assuming each group object has a 'name' field
  };
  useEffect(() => {
    // Initialize editedData with combinedData when it changes
    setEditedData(combinedData);
  }, [combinedData]);
  
  const handleCellValueChange = (newValue, rowIndex, columnName) => {
    setEditedData(prevData => {
      const updatedData = [...prevData];
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        [columnName]: newValue,
      };
      return updatedData;
    });
  };

  const options = {
    selectableRows:"none",
    textLabels: {
      body: {
        noMatch: "Nie ma żadnych uczestników :(",
        toolTip: "Sortuj",
      },
      pagination: {
        next: "Następna strona",
        previous: "Poprzednia strona",
        rowsPerPage: "Wiersze na stronę:",
        displayRows: "z" // 1-10 of 30
      },
      toolbar: {
        search: "Szukaj",
        downloadCsv: "Pobierz CSV",
        print: "Drukuj",
        viewColumns: "Zobacz kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        title: "FILTRY",
        reset: "reset",          
      },
      viewColumns: {
        title: "Pokaż kolumny"
      },
      selectedRows: {
        text: "wierszy usuniętych",
        delete: "Usuń"
      }
  }
  };

  const customToolbar = () => {
    return (
      <div style={{ textAlign: 'left', paddingLeft: '24px', paddingBottom: '8px' }}>
        <div style={{ fontSize: '14px', color: 'red' }}>Nie zapomnij zapisać swoich zmian poprzez przycisk na dole!</div>
      </div>
    );
  };
  const updatedOptions = {
    ...options,
    customToolbar,
  };
  

  
  
  return (
    <>
      <Grid container spacing={4}>
      <ToastContainer />
        <Grid item xs={12}>

 
<MUIDataTable
  title={"Wychowankowie - Oceny i notatki"}
  data={editedData}
  options={updatedOptions}
  columns={[
    {
      name: "userFirstName",
      label: "Imię",
    },
    {
      name: "userLastName",
      label: "Nazwisko",
    },
    {
      name: "pesel",
      label: "PESEL",
    },
    {
      name: "groups",
      label: "Grupa",
    },
    {
      name: "Ocena1",
      label: "Ocena I półrocze",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { rowIndex } = tableMeta;
          return (
            <TextField
            value={value}
            onChange={(event) =>
              handleCellValueChange(event.target.value, rowIndex, "Ocena1")
            }
            multiline
          />
          );
        },
      },
    },
    {
      name: "Ocena2",
      label: "Ocena II półrocze",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { rowIndex } = tableMeta;
          return (
            <TextField
              value={value}
              onChange={(event) =>
                handleCellValueChange(event.target.value, rowIndex, "Ocena2")
              }
              multiline
            />
          );
        },
      },
    },
    {
      name: "Ogolne",
      label: "Ocena ogólna",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { rowIndex } = tableMeta;
          return (
            <TextField
              value={value}
              onChange={(event) =>
                handleCellValueChange(event.target.value, rowIndex, "Ogolne")
              }
              multiline
            />
          );
        },
      },
    },
    {
      name: "Notatki",
      label: "Notatki",
      options: {
        customBodyRender: (value, tableMeta) => {
          const { rowIndex } = tableMeta;
          return (
            <TextField
              value={value}
              onChange={(event) =>
                handleCellValueChange(event.target.value, rowIndex, "Notatki")
              }
              multiline
            />
          );
        },
      },
    },

    ]}
  />

        </Grid>

        
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop:"20px" }}>
  <Button
    variant="contained"
    color="primary"
    style={{ color: 'white', alignSelf: 'center' }}
    onClick={() => {
      handleWriteDataClick();
    }}
  >
    Zapisz oceny!
  </Button>
</div>
    </>
  );
}