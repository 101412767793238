import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import BaletKlasaIAIB from "../../pages/BaletKlasaIAIB/BaletKlasaIAIB"
import BaletKlasaIAIB_Generalne from "../../pages/BaletKlasaIAIB/BaletKlasaIAIB_Generalne";
import BaletKlasaIIB from "../../pages/BaletKlasaIIB/BaletKlasaIIB";
import BaletKlasaIIIAIVA from "../../pages/BaletKlasaIIIAIVA/BaletKlasaIIIAIVA";
import BaletKlasaIVBVA from "../../pages/BaletKlasaIVBVA/BaletKlasaIVBVA";
import BaletGrupaIA from "../../pages/BaletGrupaIA/BaletGrupaIA";
import BaletGrupaIIA from "../../pages/BaletGrupaIIA/BaletGrupaIIA";
import BaletGrupaIB from "../../pages/BaletGrupaIB/BaletGrupaIB";
import BaletGrupaIIBIIIB from "../../pages/BaletGrupaIIBIIIB/BaletGrupaIIBIIIB";
import BaletGrupaWA from "../../pages/BaletGrupaWA/BaletGrupaWA";
import Pointy from "../../pages/Pointy/Pointy";
import WspolczesnyII from "../../pages/WspolczesnyII/WspolczesnyII";
import WspolczesnyVI from "../../pages/WspolczesnyVI/WspolczesnyVI";
import CapoeiraIA from "../../pages/CapoeiraIA/CapoeiraIA";
import CapoeiraIIBIIIB from "../../pages/CapoeiraIIBIIIB/CapoeiraIIBIIIB";
import CapoeiraIVB from "../../pages/CapoeiraIVB/CapoeiraIVB";
import CapoeiraVB from "../../pages/CapoeiraVB/CapoeiraVB";
import CapoeiraVIB from "../../pages/CapoeiraVIB/CapoeiraVIB";
import CapoeiraWA from "../../pages/CapoeiraWA/CapoeiraWA";
import HiphopIII from "../../pages/HiphopIII/HiphopIII";
import HiphopVI from "../../pages/HiphopVI/HiphopVI";
import JazzI from "../../pages/JazzI/JazzI";
import AkroI from "../../pages/AkroI/AkroI";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";

// context
import { useLayoutState } from "../../context/LayoutContext";
import AkroII from "../../pages/AkroII/AkroII";
import ZumbaI from "../../pages/ZumbaI/ZumbaI";
import ZumbaII from "../../pages/ZumbaII/ZumbaII";
import Zespol from "../../pages/Zespol/Zespol";
import ZespolJunior from "../../pages/ZespolJunior/ZespolJunior";
import ZespolBaby from "../../pages/ZespolBaby/ZespolBaby";
import PrzedszkoleMlodsze from "../../pages/PrzedszkoleMlodsze/PrzedszkoleMlodsze";
import PrzedszkoleStarsze from "../../pages/PrzedszkoleStarsze/PrzedszkoleStarsze";
import FitnessPN from "../../pages/FitnessPN/FitnessPN";
import FitnessWT from "../../pages/FitnessWT/FitnessWT";
import FitnessSR from "../../pages/FitnessSR/FitnessSR";
import Frekwencja from "../../pages/Frekwencja/Frekwencja";
import Aktualnosci from "../../pages/Aktualnosci/Aktualnosci";
import AdminMenu from "../../pages/AdminMenu/AdminMenu";
import GrupaTestowa from "../../pages/GrupaTestowa/GrupaTestowa";
import BaletKlasaIIBRytmika from "../../pages/BaletKlasaIIB/BaletKlasaIIBRytmika";
import BaletKlasaIIIAIVAWspolczesny from "../../pages/BaletKlasaIIIAIVA/BaletKlasaIIIAIVAWspolczesny";
import BaletKlasaIVBVAWspolczesny from "../../pages/BaletKlasaIVBVA/BaletKlasaIVBVAWspolczesny";
import BaletKlasaIAIBRytmika from "../../pages/BaletKlasaIAIB/BaletKlasaIAIBRytmika";
import Group from "../../pages/Group/Group";
import ManagerGrup from "../../pages/ManagerGrup/ManagerGrup";
import ManagerLekcji from "../../pages/ManagerGrup/ManagerLekcji";
import Wychowankowie from "../../pages/Wychowankowie/Wychowankowie";
function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/wychowankowie" component={Wychowankowie}></Route>
              <Route path="/app/plan" component={Dashboard} />
              <Route path="/app/group" component={Group} />
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/balet/BaletKlasaIAIB" component={BaletKlasaIAIB} />
              <Route path="/app/balet/BaletKlasaIAIBRytmika" component={BaletKlasaIAIBRytmika} />
              <Route path="/app/balet/BaletKlasaIAIB_Generalne" component={BaletKlasaIAIB_Generalne} />
<Route path="/app/balet/BaletKlasaIIB" component={BaletKlasaIIB} />
<Route path="/app/balet/BaletKlasaIIBRytmika" component={BaletKlasaIIBRytmika} />
<Route path="/app/balet/BaletKlasaIIIAIVA" component={BaletKlasaIIIAIVA} />
<Route path="/app/balet/BaletKlasaIIIAIVAWspolczesny" component={BaletKlasaIIIAIVAWspolczesny} />
<Route path="/app/balet/BaletKlasaIVBVA" component={BaletKlasaIVBVA} />
<Route path="/app/balet/BaletKlasaIVBVAWspolczesny" component={BaletKlasaIVBVAWspolczesny} />
<Route path="/app/balet/BaletGrupaIA" component={BaletGrupaIA} />
<Route path="/app/balet/BaletGrupaIIA" component={BaletGrupaIIA} />
<Route path="/app/balet/BaletGrupaIB" component={BaletGrupaIB} />
<Route path="/app/balet/BaletGrupaIIB" component={Dashboard} />
<Route path="/app/balet/BaletGrupaIIBIIIB" component={BaletGrupaIIBIIIB} />
<Route path="/app/balet/BaletGrupaWA" component={BaletGrupaWA} />
<Route path="/app/balet/Pointy" component={Pointy} />
<Route path="/app/wspolczesny/WspolczesnyII" component={WspolczesnyII} />
<Route path="/app/wspolczesny/WspolczesnyVI" component={WspolczesnyVI} />
<Route path="/app/capoeira/CapoeiraIA" component={CapoeiraIA} />
<Route path="/app/capoeira/CapoeiraIIBIIIB" component={CapoeiraIIBIIIB} />
<Route path="/app/capoeira/CapoeiraIVB" component={CapoeiraIVB} />
<Route path="/app/capoeira/CapoeiraVB" component={CapoeiraVB} />
<Route path="/app/capoeira/CapoeiraVIB" component={CapoeiraVIB} />
<Route path="/app/capoeira/CapoeiraWA" component={CapoeiraWA} />
<Route path="/app/hiphop/HipHopIII" component={HiphopIII} />
<Route path="/app/hiphop/HipHopVI" component={HiphopVI} />
<Route path="/app/jazz/JazzI" component={JazzI} />
<Route path="/app/akro/AkroI" component={AkroI} />
<Route path="/app/akro/AkroII" component={AkroII} />
<Route path="/app/zumba/ZumbaI" component={ZumbaI} />
<Route path="/app/zumba/ZumbaII" component={ZumbaII} />
<Route path="/app/zespol/Zespol" component={Zespol} />
<Route path="/app/zespol/ZespolJunior" component={ZespolJunior} />
<Route path="/app/zespol/ZespolBaby" component={ZespolBaby} />
<Route path="/app/przedszkole/PrzedszkoleMlodsze" component={PrzedszkoleMlodsze} />
<Route path="/app/przedszkole/PrzedszkoleStarsze" component={PrzedszkoleStarsze} />
<Route path="/app/fitness/FitnessPN" component={FitnessPN} />
<Route path="/app/fitness/FitnessWT" component={FitnessWT} />
<Route path="/app/fitness/FitnessSR" component={FitnessSR} />
<Route path='/app/frekwencja' component={Frekwencja} />
<Route path='/app/Aktualnosci' component={Aktualnosci} />
<Route path='/app/admin' component={AdminMenu} />
<Route path='/app/grupatestowa' component={GrupaTestowa} />
<Route path='/app/managergrup' component={ManagerGrup} />
<Route path='/app/managerlekcji' component={ManagerLekcji} />
              <Route path="/app/typography" component={Typography} />
              <Route path="/app/tables" component={Maps} />
              <Route path="/app/notifications" component={Notifications} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
                {/*}
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Flatlogic
                </Link>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/about'}
                  target={'_blank'}
                  className={classes.link}
                >
                  About Us
                </Link>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/blog'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Blog
                </Link>
              </div>
              <div>
                <Link
                  href={'https://www.facebook.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="facebook">
                    <Icon
                      path={FacebookIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://twitter.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
          {*/}
                 <p>Made with ❤️ by <Link
                  href={'https://www.linkedin.com/in/damian-chymkowski/'}
                  target={'_blank'}
                  
                >Damian Chymkowski</Link></p>
                
              </div>
            </Box>
            
          </div>
          
        </>
        
    </div>
    
  );
}

export default withRouter(Layout);
