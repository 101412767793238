import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { TourProvider } from '@reactour/tour'


const steps = [
  {
    selector: '.first-step',
    content: 'Witam w przewodniku sprawdzania obecności. Fajnie, że przeżyjesz ze mną swój pierwszy raz! W tej sekcji zobaczysz nazwę swojej grupy',
    
  },
  {
    selector: '.second-step',
    content: 'Tutaj możesz kliknąć by wybrać inną datę lekcji, lub przełączyć na historyczne wpisy.',
    
  },
  {
    selector: '.third-step',
    content: 'Kliknij, aby odświeżyć dane. Pomocne w przypadku kiedy Twoje dane wydają Ci się niepoprawne. Zwłaszcza kiedy filtrujesz po historycznych lekcjach.',
    
  },
  {
    selector: '.fourth-step',
    content: 'Kilknij, aby zresetować lekcję. Jeśli przez pomyłkę dodałeś/aś jakiegoś użytkownika, lub chciałbyś/aś zacząć sprawdzanie od początku',
    
  },
  {
    selector: '.fifth-step',
    content: 'Ten przycisk nieodwracalnie usuwa lekcję z bazy. Kliknij, jeśli sprawdziłeś/aś obecność np. dla błędnej grupy lub daty',
    
  },
  {
    selector: '.six-step',
    content: 'W tym polu wpisz temat zajęć. Ten musi być składny i odzwierciedlać to co będzie działo się na zajęciach',
    
  },
  {
    selector: '.seven-step',
    content: 'To jest pełna liczba OBECNYCH uczestników zajęć. Jest tu podana informacyjnie',
    
  },
  {
    selector: '.eight-step',
    content: 'To jest właściwie najważniejszy element dziennika. To tu sprawdzasz obecność',
    
  },
  {
    selector: '.nine-step',
    content: 'Id to inaczej liczba porządkowa ucznia',
    
  },
  {
    selector: '.ten-step',
    content: 'Znajduje się tu także Imię i Nazwisko pociechy',
    
  },
  {
    selector: '.eleven-step',
    content: 'Zaznaczysz też obecność uczestnika zajęć. Jeśli jest obecny, kliknij kwadracik. Jeśli nie jest obecny, pozostaw puste.',
    
  },
  {
    selector: '.twelve-step',
    content: 'Jeśli uczestnik zajęć spóźnił się na zajęcia, zaznacz jednocześnie obecny i spóźniony',
    
  },
  {
    selector: '.trzynascie-step',
    content: 'Znajduje się tu też specjalna rubryka do odrabiania. Zaznacz TYLKO jeśli dodałeś/aś uczestnika spoza grupy używając przycisku "Dodaj uczestnika". Zaznacz wtedy jego obecnośc oraz odrabianie',
    
  },
  {
    selector: '.czternascie-step',
    content: 'Jeśli masz uwagi do uczestnika zajęć, wpisz je w polu Uwagi. Pomocne, gdy dziecko rozrabia, lub zrobiło coś nietypowego np. włożyło palce do kontaktu ;)',
    
  },
  {
    selector: '.pietnascie-step',
    content: 'Kliknij, by dodać uczestnika zajęć spoza standardowej listy obecności',
    
  },
  {
    selector: '.szesnascie-step',
    content: 'To Twój podpis. Jest wymagany. Postaraj się za każdym razem używać tej samej formy podpisu',
    
  },
  {
    selector: '.siedemnascie-step',
    content: 'Kliknij, by zapisać lekcję. Po kliknięciu przycisku nie będzie możliwości edycji, o ile nie klikniesz przyciskuu EDYTUJ',
    
  },
  {
    selector: '.osiemnascie-step',
    content: 'A to jest właśnie ten przycisk. Dziękuję bardzo za tutorial. To już wszystko! W razie problemów, możesz otworzyć te wprowadzenie jeszcze raz!',
    
  },




]

ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
      <ThemeProvider theme={Themes.default}>
        <CssBaseline />
        <TourProvider steps={steps}>
        <App />
        </TourProvider>
       
      </ThemeProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById("root"),
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

