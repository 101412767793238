import {React, useEffect, useRef, useState, useReducer} from "react";
import { Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import {Checkbox, TextField, Button, Typography} from "@material-ui/core";
import {Input} from "@material-ui/core";
// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import {Table} from "@material-ui/core";
import { DataGrid, GridColDef, GridValueGetterParams} from '@material-ui/data-grid';
// data
import mock from "../dashboard/mock";
import { createTheme } from '@material-ui/core/styles'
import {DatePicker, DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MenuButton  from "@material-ui/icons/Menu"
import {
  Paper,
  Menu,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))



export default function Tables() {

  const Temat = useRef([]);
  const Grupa = "capoeira1a";
  const dvalue = useRef("");
  const nameRef = useRef("");
  const disabler = useRef("");
  const info = useRef("");
  const podpis = useRef("");
  const warning = useRef("");
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  var [moreButtonRef, setMoreButtonRef] = useState(null);
  var [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
  var lucz = 0;
  const [liczbaUczestnikow, setLiczbaUczestnikow] = useState(0)
  const [Uczestnicy, setUczestnicy] = useState(
  [
    { 
      id:0,
      name: "Damian Chymkowski",
      obecnosc: false,
      spozniony: false,
      odrabianie: false,
      uwagi: ""
    },
    { 
      id:1,
      name: "Klaudia Chymkowska",
      obecnosc: false,
      spozniony: false,
      odrabianie: false,
      uwagi: ""
    },
    { 
      id:2,
      name: "Maria Staniszewska",
      obecnosc: false,
      spozniony: false,
      odrabianie: false,
      uwagi: ""
    },
    { 
      id:3,
      name: "Piotr Kudlarczyk",
      obecnosc: false,
      spozniony: false,
      odrabianie: false,
      uwagi: ""
    },
  ]);

useEffect(()=>
{
  odswiezUczestnikow()
})
 
function odswiezUczestnikow()
{lucz = 0;
  for(let element in Uczestnicy)
  {
    
    if(Uczestnicy[element].obecnosc == true || Uczestnicy[element].spozniony == true || Uczestnicy[element].odrabianie == true)
    {
      lucz++;
      setLiczbaUczestnikow(lucz);
    }
    if(lucz == 0)
    {
      setLiczbaUczestnikow(0);
    }
  }
}
function UczestnikComponent(props) {

  const [imie, setImie] = useState(props.name)
  const [id, setId] = useState(props.id)
  const [obecnosc, setObecnosc] = useState(props.obecnosc)
  const [spozniony, setSpozniony] = useState(props.spozniony)
  const [odrabianie, setOdrabianie] = useState(props.odrabianie)
  const [uwagi, setUwagi] = useState(props.uwagi);
  const liftState = () =>
  {
setUczestnicy(Uczestnicy)
odswiezUczestnikow();
  }
 const handleObecnosc = (id) =>
  {
   
   Uczestnicy[id].obecnosc = !Uczestnicy[id].obecnosc
   console.log(Uczestnicy)
   liftState();
  }
  const handleSpozniony = (id) =>
  {
   
   Uczestnicy[id].spozniony = !Uczestnicy[id].spozniony;
   console.log(Uczestnicy)
   liftState();
  }
  const handleOdrabianie = (id) =>
  {
   
   Uczestnicy[id].odrabianie = !Uczestnicy[id].odrabianie;
   console.log(Uczestnicy)
   liftState();
  }
  const handleUwagi = (id,uwagi) =>
  {
   
   Uczestnicy[id].uwagi = uwagi;
   console.log(Uczestnicy)
   liftState();
  }
  
  return (
  <>
  <Grid container spacing={2} >
  
  <Grid item xs={0.5} >
<Typography>{props.id}</Typography>
  </Grid>
<Grid item xs={2}>
<Typography>{props.name}</Typography>
</Grid>
<Grid item xs>
<Checkbox defaultChecked={Uczestnicy[props.id].obecnosc} onChange={() => {handleObecnosc(props.id)}}></Checkbox>
</Grid>
<Grid item xs>
<Checkbox defaultChecked={Uczestnicy[props.id].spozniony} onChange={() => {handleSpozniony(props.id)}}></Checkbox>
</Grid>
<Grid item xs>
<Checkbox defaultChecked={Uczestnicy[props.id]?.odrabianie} onChange={() => {handleOdrabianie(props.id)}}></Checkbox>
</Grid>
<Grid item xs={4}>
<TextField disabled={!props.active} multiline style={{width:"100%"}} defaultValue={Uczestnicy[props.id].uwagi} onChange={(e) => {setUwagi(e.target.value)}}onBlur={(e) => {handleUwagi(props.id, uwagi)}}></TextField>
</Grid>

</Grid>

  </>
  );
}

const DodajUczestnikaModal = () =>{
  const [name, setName] = useState("");
  const [clicked, setClicked] = useState(false);
   const editName = (name) =>{
   setName(name);
   
 }

       return(
        <>
        
        <Button style={{marginLeft:"10px"}} onClick={()=>{setClicked(!clicked)}}>Dodaj uczestnika</Button>
        { clicked ? <><TextField
           variant="outlined"
           margin="normal"
           fullWidth
           value={name}
           inputRef={nameRef}
           onChange={e => setName(e.target.value)}
           label="Wprowadź imię i nazwisko"
         />
         <Button variant="contained" color="secondary" onClick={() => {DodajUczestnika(nameRef.current.value);console.log(nameRef.current.calue)}}>Dodaj</Button></> : <p></p> }
         
         </>
     )
 }


  const [zaj, setZaj] = useState([]);

 useEffect(()=>
 {
  Retrieve();
  for(let el in Uczestnicy)
  {
    setZaj(zaj => [...zaj,<UczestnikComponent id={Uczestnicy[el].id} obecnosc={Uczestnicy[el].obecnosc} spozniony = {Uczestnicy[el].spozniony} odrabianie ={Uczestnicy[el].odrabianie} uwagi="" key={Math.random()} name={Uczestnicy[el].name}></UczestnikComponent>])
  }
 },[]);

  const classes = useStyles();
  var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;
var ReadyToSend;

const DodajUczestnika = (name) =>
{
    let ids = Uczestnicy.length
    Uczestnicy.push({id:ids,name:name,obecnosc:false,spozniony:false,odrabianie:false,uwagi:""})
    setZaj(zaj => [...zaj,<UczestnikComponent id={ids} obecnosc={false} spozniony = {false} odrabianie ={false} uwagi="" key={Math.random()} name={name}></UczestnikComponent>])
}

function Send()
  {
    if(podpis.current.value == "" || podpis.current.value == undefined || podpis.current.value == "null")
    {
      warning.current.innerText = "Podpis prowadzącego jest pusty!"
    }
    else
    {

    warning.current.innerText = "";
    disabler.current.style.pointerEvents = "none";
    info.current.innerText = "Jesteś w trybie poglądu, kliknij przycisk Edytuj aby edytować"
    var ReadyToSend = {
      "Temat":Temat.current.value,
      "Grupa":Grupa,
      "Data":dvalue.current.value,
      "Uczestnicy":Uczestnicy,
      "Podpis":podpis.current.value
    };
    let Finalstr = 'https://mobileappscrapper.herokuapp.com/setdb/' + ReadyToSend.Grupa.toString() + "_" + "Obecnosc" + "_" + ReadyToSend.Data.toString() + "_" + JSON.stringify(ReadyToSend);
    fetch(Finalstr)
  .then((response) => response.json())
  .then((data) => console.log(data));

console.log(Uczestnicy)
}
  }
  function setView()
  {
    forceUpdate()
    setZaj([])
    for(let el in Uczestnicy)
    {
      
      setZaj(zaj => [...zaj,<UczestnikComponent id={Uczestnicy[el].id} obecnosc={Uczestnicy[el].obecnosc} spozniony = {Uczestnicy[el].spozniony} odrabianie ={Uczestnicy[el].odrabianie} uwagi="" key={Math.random()} name={Uczestnicy[el].name}></UczestnikComponent>])
    }
    
  }
 
  async function Retrieve() {
    // method body
    var data;
    ReadyToSend = {
      "Temat":Temat.current.value,
      "Grupa":Grupa,
      "Data":dvalue.current.value,
      "Uczestnicy":Uczestnicy,
      "Podpis":podpis.current.value
    };
    let Finalstr = 'https://mobileappscrapper.herokuapp.com/getdb/' + ReadyToSend.Grupa.toString() + "_" + "Obecnosc" + "_" + ReadyToSend.Data.toString() + "_";
    await fetch(Finalstr)
      .then((response) => response.json())
      .then((json) => {
        // body
        data = json;
        console.log(json);
      });

    setUczestnicy(data.Uczestnicy);
    setView();
    Temat.current.value = data.Temat;
    podpis.current.value = data.Podpis;
  }
  

  return (
    <>
     
      <Grid>
      <PageTitle title="Capoeira" />
      <Typography ref={info}></Typography>
      
      <Widget>
       

        
      <Typography>Lekcja</Typography> 
      <Typography><input style={{fontSize:"3vmin",fontWeight:100}} defaultValue={today} ref={dvalue} type="date" onChange={()=>{Retrieve(dvalue.current.value)}}></input><Button style={{color:"white", marginLeft:"10px", marginBottom:"10px"}}variant="contained" color="primary" onClick={()=> {Retrieve(dvalue.current.value)}}>Wczytaj</Button> </Typography>
      
      
      
      
      <Grid container spacing={4}>
        <Grid item xs={12} style={{textAlign:"center"}}>
          <Typography><b>Temat zajęć</b></Typography>
        <TextField inputProps={{min: 0, style: { textAlign: 'center' }}} style={{textAlign:"center",justifyContent:"center"}} fullWidth inputRef={Temat} InputLabelProps={{ shrink: true }}  label=""></TextField>
        <Typography>Liczba uczestników: {liczbaUczestnikow}</Typography>
        <br></br>
        <br></br>
        
       
        
         
     


      
        
        </Grid>
        
        </Grid>
        
        <Grid item xs={1}>
<IconButton
                  color="primary"
                  
                  classes={{ root: classes.moreButton }}
                  aria-owns="widget-menu"
                  aria-haspopup="true"
                  onClick={() => setMoreMenuOpen(true)}
                  buttonRef={setMoreButtonRef}
                ><MenuButton style={{marginLeft:"-20px"}}></MenuButton></IconButton> <Menu
        id="widget-menu"
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
      >
        <MenuItem>
          <Typography onClick={()=>{for(let element in Uczestnicy){Uczestnicy[element].obecnosc = Uczestnicy[element].obecnosc = true}setView();setMoreMenuOpen(false)}}>Wszyscy obecni</Typography>
        </MenuItem>
        <MenuItem>
          <Typography onClick={()=>{disabler.current.style.pointerEvents = "initial";info.current.innerText = "Jesteś w trybie poglądu, kliknij przycisk Edytuj aby edytować";setMoreMenuOpen(false)}}>Edytuj</Typography>
        </MenuItem>
      </Menu>
      
</Grid>

<div ref={disabler} style={{pointerEvents:"initial"}}>
        <Grid container spacing={1}>

<Grid item>
<b>Id</b>
</Grid>
<Grid item xs={2}>
<b>Imię i nazwisko</b>
</Grid>
<Grid item xs onClick={() => {for(let element in Uczestnicy) {Uczestnicy[element].obecnosc = !Uczestnicy[element].obecnosc;setView()}}}>
<b>Obecność</b>
</Grid>
<Grid item xs>
<b>Spóźniony</b>
</Grid>
<Grid item xs>
<b>Odrabianie</b>
</Grid>
<Grid item xs={4}>
<b>Uwagi</b>
</Grid>


<br></br>
<br></br>

</Grid>

{zaj}
<DodajUczestnikaModal></DodajUczestnikaModal>  
</div>
        </Widget>
      
      </Grid>
      
        <Grid container spacing={4}>

        <Grid item xs={12}>
         
           
          <Widget>
        <TextField InputLabelProps={{ shrink: true }} inputRef={podpis} label="Podpis prowadzącego"></TextField><br></br><br></br>
        <Button style={{color:"white"}} color="primary" variant="contained" onClick={()=> {Send()}}>Zapisz</Button>
        <Button style={{marginLeft:"10px"}}  onClick={()=>{disabler.current.style.pointerEvents="initial"; info.current.innerText = ""}}>Edytuj</Button>
        <div ref={warning} style={{color:"red"}}></div>
        </Widget>
        
        </Grid>
        
        </Grid>
        

        
  
    

   
      </>
      
  );
}
