import {React, useEffect, useRef, useState, useReducer} from "react";
import { Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import {Checkbox, TextField, Button, Typography} from "@material-ui/core";
import {Input} from "@material-ui/core";
// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import {Table} from "@material-ui/core";
import { DataGrid, GridColDef, GridValueGetterParams} from '@material-ui/data-grid';
// data
import mock from "../dashboard/mock";
import { createTheme } from '@material-ui/core/styles'
import MenuButton  from "@material-ui/icons/Menu"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";
import {
  Paper,
  Menu,
  MenuItem,
  FormControlLabel,
  FormGroup,
  FormControl
} from "@material-ui/core";
import axios from 'axios';
import './styles.css';
import { ToastContainer, toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },
  datepicker: {
    backgroundColor: theme.palette.primary.main,
  },

}))



export default function AkroII() {
  registerLocale("pl", pl);
  const Temat = useRef([]);
  const Grupa = "AkroII";
  const dvalue = useRef("");
  const nameRef = useRef("");
  const disabler = useRef("");
  const info = useRef("");
  const podpis = useRef("");
  const warning = useRef("");
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  var [moreButtonRef, setMoreButtonRef] = useState(null);
  var [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
  var lucz = 0;
  const [liczbaUczestnikow, setLiczbaUczestnikow] = useState(0)
  const [Dates, setDates] = useState([]);
  const [Uczestnicy, setUczestnicy] = useState([]);
  const [UczestnicyBase, setUczestnicyBase] = useState([]);
  
  const notifySuccess = (text) => toast.success(text, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
    const notifyError = (text) => toast.error(text, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

  useEffect(()=>{
    Highlight(Grupa);
  }, [])

  const GetBase = () => {
    setZaj([])

    axios.get(`http://mobileappscrapper.herokuapp.com/setuczestnicy/get` + "_" + Grupa + "_")
      .then(res2 => {
        const res = JSON.parse(res2.data[0].Uczestnicy);

        const newUczestnicy = res.map(element => ({
          id: element.id,
          name: element.name,
          active: element.active,
          obecnosc: false,
          spozniony: false,
          odrabianie: false,
          uwagi: "",
        }));
        setUczestnicy(newUczestnicy);
        setUczestnicyBase(newUczestnicy);

      })
      .catch(error => {
        console.error(error);
      });
    };
  
  useEffect(() => {
    setZaj([])
    
    odswiezUczestnikow();
    setView();
    
  }, [Uczestnicy, liczbaUczestnikow]);

useEffect(()=>{Retrieve(selectedDate.toISOString().split('T')[0]); odswiezUczestnikow();}, [])

 
 
function odswiezUczestnikow()
{lucz = 0;
  for(let element in Uczestnicy)
  {
    
    if(Uczestnicy[element].obecnosc == true || Uczestnicy[element].spozniony == true || Uczestnicy[element].odrabianie == true)
    {
      lucz++;
      setLiczbaUczestnikow(lucz);
    }
    if(lucz == 0)
    {
      setLiczbaUczestnikow(0);
    }
  }
}

function UczestnikComponent(props) {

  const [imie, setImie] = useState(props.name)
  const [id, setId] = useState(props.id)
  const [obecnosc, setObecnosc] = useState(props.obecnosc)
  const [spozniony, setSpozniony] = useState(props.spozniony)
  const [odrabianie, setOdrabianie] = useState(props.odrabianie)
  const [uwagi, setUwagi] = useState(props.uwagi);


  const liftState = () =>
  {
    
setUczestnicy(Uczestnicy)
odswiezUczestnikow();
  }
 const handleObecnosc = (id) =>
  {
   
   Uczestnicy[id].obecnosc = !Uczestnicy[id].obecnosc
   if(Uczestnicy[id].obecnosc == true)
   {
    Uczestnicy[id].odrabianie = false;
    setView()
   }
   
   liftState();
  }
  const handleSpozniony = (id) =>
  {
   
   Uczestnicy[id].spozniony = !Uczestnicy[id].spozniony;
   
   
   liftState();
  }
  const handleOdrabianie = (id) =>
  {
   
   Uczestnicy[id].odrabianie = !Uczestnicy[id].odrabianie;
   if(Uczestnicy[id].odrabianie == true)
   {
    Uczestnicy[id].obecnosc = false;
    setView()
   }
   
   liftState();
  }
  const handleUwagi = (id,uwagi) =>
  {
   
   Uczestnicy[id].uwagi = uwagi;
  
   liftState();
   
   
  }
  return (
  <>
  <Grid container spacing={2} >
  
  <Grid item>
<Typography>{props.id+1}</Typography>
  </Grid>
<Grid item xs={2}>
<Typography>{props.name}</Typography>
</Grid>
<Grid item xs>
<Checkbox disabled={!props.active} defaultChecked={Uczestnicy[props.id]?.obecnosc} onChange={() => {handleObecnosc(props.id)}}></Checkbox>
</Grid>
<Grid item xs>
<Checkbox disabled={!props.active} defaultChecked={Uczestnicy[props.id]?.spozniony} onChange={() => {handleSpozniony(props.id)}}></Checkbox>
</Grid>
<Grid item xs>
<Checkbox disabled={!props.active} defaultChecked={Uczestnicy[props.id]?.odrabianie} onChange={() => {handleOdrabianie(props.id)}}></Checkbox>
</Grid>
<Grid item xs={4}>
  <TextField
    disabled={!props.active}
    multiline
    style={{ width: "100%" }}
    defaultValue={Uczestnicy[props.id]?.uwagi}
    onChange={(e) => {
      setUwagi(e.target.value);
    }}
    onBlur={(e) => {
      handleUwagi(props.id, uwagi);
    }}
  />
</Grid>

</Grid>

  </>
  );
}

const DodajUczestnikaModal = () =>{
  const [name, setName] = useState("");
  const [active, setActive] = useState(true);
  const [clicked, setClicked] = useState(false);
   const editName = (name) =>{
   setName(name);
   
 }

       return(
        <>
        
        <Button style={{marginLeft:"10px"}} onClick={()=>{setClicked(!clicked)}}>Dodaj uczestnika</Button>
        { clicked ? <><TextField
           variant="outlined"
           margin="normal"
           fullWidth
           value={name}
           inputRef={nameRef}
           onChange={e => setName(e.target.value)}
           label="Wprowadź imię i nazwisko"
         />
         <FormGroup>
  <FormControlLabel control={<Checkbox
           defaultChecked={true}
           onChange={(e) => {setActive(e.target.checked)}}
           label="Aktywny"
         />} label="Aktywny" />
</FormGroup>
         
         <Button variant="contained" color="secondary" onClick={() => {DodajUczestnika(nameRef.current.value, active);}}>Dodaj</Button></> : <p></p> }
         
         </>
     )
 }
 /*
 useEffect(()=>
 {
  Retrieve();
  for(let el in Uczestnicy)
  {
    setZaj(zaj => [...zaj,<UczestnikComponent id={Uczestnicy[el].id} obecnosc={Uczestnicy[el].obecnosc} spozniony = {Uczestnicy[el].spozniony} odrabianie ={Uczestnicy[el].odrabianie} uwagi="" key={Math.random()} name={Uczestnicy[el].name}></UczestnikComponent>])
  }
  setView();
 },[]);

*/

  const [zaj, setZaj] = useState([]);

 
  const classes = useStyles();
  var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;
var ReadyToSend;

const DodajUczestnika = (name, active) =>
{

    Uczestnicy.push({active: active, id:Uczestnicy.length,name:name,obecnosc:false,spozniony:false,odrabianie:false,uwagi:""})
    setZaj(zaj => [...zaj,<UczestnikComponent active={active} id={Uczestnicy.length} obecnosc={false} spozniony = {false} odrabianie ={false} uwagi="" key={Math.random()} name={name}></UczestnikComponent>])
    
    notifySuccess("Uczestnik dodany!")
}

function Send()
  {
    if(podpis.current.value == "" || podpis.current.value == undefined || podpis.current.value == null)
    {
      warning.current.innerText = "Podpis prowadzącego jest pusty!"
      notifyError("Podpis prowadzącego jest pusty!")
    }
    else
    {
      if(Temat.current.value == "" || Temat.current.value == undefined || Temat.current.value == null)
      {
        warning.current.innerText = "Temat jest pusty!"
        notifyError("Temat jest pusty!")
      }
      else
      {
warning.current.innerText = "";
    disabler.current.style.pointerEvents = "none";
    info.current.innerText = "Jesteś w trybie poglądu, kliknij przycisk Edytuj aby edytować"
    var ReadyToSend = {
      "Temat":Temat.current.value,
      "Grupa":Grupa,
      "Data":selectedDate.toISOString().split('T')[0],
      "Uczestnicy":Uczestnicy,
      "Podpis":podpis.current.value
    };
    let Finalstr = 'https://mobileappscrapper.herokuapp.com/setdb2/' + ReadyToSend.Grupa.toString() + "_" + "Obecnosc" + "_" + ReadyToSend.Data + "_" + JSON.stringify(ReadyToSend);
    fetch(Finalstr)
  .then((response) => response.json())


notifySuccess("Pomyślnie zapisano!")
      }
    
}
  }
  function setView()
  {
    setZaj([])
    setZaj([])
    setZaj([])
    for(let el in Uczestnicy)
    {
      if(Uczestnicy[el].active == undefined)
      {
        Uczestnicy[el].active = true
      }
      setZaj(zaj => [...zaj,<UczestnikComponent active={Uczestnicy[el].active} id={Uczestnicy[el].id} obecnosc={Uczestnicy[el].obecnosc} spozniony = {Uczestnicy[el].spozniony} odrabianie ={Uczestnicy[el].odrabianie} uwagi="" key={Math.random()} name={Uczestnicy[el].name}></UczestnikComponent>])
    }

  }
  function Resetuj()
  {
/*
    setZaj([])
    for(let element in Uczestnicy)
  {
    Uczestnicy[element] = UczestnicyBase[element]
  }

    for(let el in Uczestnicy)
    {
      
      setZaj(zaj => [...zaj,<UczestnikComponent id={Uczestnicy[el].id} obecnosc={Uczestnicy[el].obecnosc} spozniony = {Uczestnicy[el].spozniony} odrabianie ={Uczestnicy[el].odrabianie} uwagi="" key={Math.random()} name={Uczestnicy[el].name}></UczestnikComponent>])
    }
    */
    setZaj([])
    GetBase()
    setView();
    odswiezUczestnikow();
    Temat.current.value = "";
    podpis.current.value = "";
    notifySuccess("Pomyślnie zresetowano!")
  }
 
  async function Retrieve(date) {
    // method body
    var data;
    ReadyToSend = {
      "Temat":Temat.current.value,
      "Grupa":Grupa,
      "Data":date,
      "Uczestnicy":Uczestnicy,
      "Podpis":podpis.current.value
    };
    let Finalstr = 'https://mobileappscrapper.herokuapp.com/getdb2/damian|' + ReadyToSend.Grupa.toString() + "_" + "Obecnosc" + "_" + ReadyToSend.Data;

    axios.get(Finalstr)
      .then(response => data = JSON.parse(response.data[0]))
      .catch(() => {GetBase()})
      .then(response => data)
      .finally((response)=>
      {
        if(data !== undefined)
        {
          setZaj([])
          setView();
          setUczestnicy(data.Uczestnicy);

          Temat.current.value = data.Temat;
          podpis.current.value = data.Podpis;

          odswiezUczestnikow();
          odswiezUczestnikow();
          odswiezUczestnikow();
          odswiezUczestnikow();
          notifySuccess("Pomyślnie wczytano: " + date)
        }
        else
        {
          setZaj([])
          
          GetBase();
          Temat.current.value = ""
          podpis.current.value = ""
          odswiezUczestnikow();
          
        }
          
        
        
      }
      )


  }
  const Highlight = (Grupa) =>
  {
    axios.get(`https://mobileappscrapper.herokuapp.com/getwholedb/damian`)
  .then(res => {
    const persons = res.data[0];
    
    let Dates = [];
    let Uczestnicy = [];
    let Capoeira = [];
    let lekcje;
   
    for(let element in persons)
    {
      let groupname = element.split("_");
      if(groupname[0] == Grupa)
      {

          lekcje = JSON.parse(persons[element].Lekcja);
        

      }
      for(let element in lekcje)
      {
        Dates.push(lekcje.Data);
        
      }
      
      
    }
    const nonDuplicateElements = [...new Set(Dates)];
  

    const dates = nonDuplicateElements.map((stringDate) => {
      return new Date(stringDate);
    });

    const highlightDate = (date, color) => {
      const highlightedDate = new Date(date);
      highlightedDate.style = `background-color: ${color}`;
      return highlightedDate;
    }
  
    const datesToHighlight = dates;
  setDates(datesToHighlight.map(date => highlightDate(date, 'yellow')))
  })
    
  }

  


  const [selectedDate, setSelectedDate] = useState(new Date());
  return (
    <>
     
      <Grid>
        
      <PageTitle className="lekcja" title="Akro II" />
      <Typography ref={info}></Typography>
      
      <Widget>
       

        <FormControl>
        <label style={{textAlign:"center"}} htmlFor="date-picker">Wybierz datę</label>
        <DatePicker
    id="date-picker"
    containerStyle={{width: '100%'}}
    wrapperClassName="datepicker"
    className="datepicker"
    dateFormat='dd/MM/yyyy' 
    todayButton={"Dzisiaj"}
    locale="pl"
    fixedHeight
    label="Lekcja"
    popperPlacement="bottom"
    highlightDates = {Dates}
    ref={dvalue}
    defaultValue={selectedDate}
    selected={selectedDate}
    
    onChange={(date) => {setSelectedDate(date); Retrieve(date.toISOString().split('T')[0]);}}
/>
        </FormControl>
<br></br>  
<br></br>
<Button style={{color:"white", marginLeft:"10px", marginBottom:"10px"}}variant="contained" color="primary" onClick={()=> {Retrieve(selectedDate.toISOString().split('T')[0])}}>Wczytaj</Button><Button style={{color:"white", marginLeft:"10px", marginBottom:"10px"}}variant="contained" onClick={()=> {Resetuj()}}>Resetuj</Button> 
      
      
      
      
      
      <Grid container spacing={4}>
        <Grid item xs={12} style={{textAlign:"center"}}>
          <Typography><b>Temat zajęć</b></Typography>
        <TextField inputProps={{min: 0, style: { textAlign: 'center' }}} style={{textAlign:"center",justifyContent:"center"}} fullWidth inputRef={Temat} InputLabelProps={{ shrink: true }}  label=""></TextField>
        <Typography>Liczba uczestników: {liczbaUczestnikow}</Typography>
        <br></br>
        <br></br>
        
       
        
         
     


      
        
        </Grid>
        
        </Grid>
        
        <Grid item xs={1}>
<IconButton
                  color="primary"
                  
                  classes={{ root: classes.moreButton }}
                  aria-owns="widget-menu"
                  aria-haspopup="true"
                  onClick={() => setMoreMenuOpen(true)}
                  ref={setMoreButtonRef}
                ><MenuButton style={{marginLeft:"-20px"}}></MenuButton></IconButton> <Menu
        id="widget-menu"
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
      >
        <MenuItem>
          <Typography onClick={()=>{for(let element in Uczestnicy){Uczestnicy[element].obecnosc = Uczestnicy[element].obecnosc = true}setView();setMoreMenuOpen(false)}}>Wszyscy obecni</Typography>
        </MenuItem>
        <MenuItem>
          <Typography onClick={()=>{disabler.current.style.pointerEvents = "initial";info.current.innerText = "Jesteś w trybie poglądu, kliknij przycisk Edytuj aby edytować";setMoreMenuOpen(false)}}>Edytuj</Typography>
        </MenuItem>
      </Menu>
      
</Grid>

<div ref={disabler} style={{pointerEvents:"initial"}}>
        <Grid container spacing={1}>

<Grid item>
<b>Id</b>
</Grid>
<Grid item xs={2}>
<b>Imię i nazwisko</b>
</Grid>
<Grid item xs onClick={() => {for(let element in Uczestnicy) {Uczestnicy[element].obecnosc = !Uczestnicy[element].obecnosc;setView()}}}>
<b>Obecność</b>
</Grid>
<Grid item xs>
<b>Spóźniony</b>
</Grid>
<Grid item xs>
<b>Odrabianie</b>
</Grid>
<Grid item xs={4}>
<b>Uwagi</b>
</Grid>


<br></br>
<br></br>

</Grid>

{zaj}
<DodajUczestnikaModal></DodajUczestnikaModal>  
</div>
        </Widget>
      
      </Grid>
      
        <Grid container spacing={4}>

        <Grid item xs={12}>

      
          <Widget>
        <TextField InputLabelProps={{ shrink: true }} inputRef={podpis} label="Podpis prowadzącego"></TextField><br></br><br></br>
        <Button style={{color:"white"}} color="primary" variant="contained" onClick={()=> {Send()}}>Zapisz</Button>
        <Button style={{marginLeft:"10px"}}  onClick={()=>{disabler.current.style.pointerEvents="initial"; info.current.innerText = ""}}>Edytuj</Button>
        <div ref={warning} style={{color:"red"}}></div>
    </Widget>
        </Grid>
        </Grid>
        <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
draggable
theme="light"
/>
        

        
  
    

   
      </>
      
  );
}
