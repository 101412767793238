import {React, useEffect, useRef, useState, useReducer} from "react";
import { Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import {Checkbox, TextField, Button, Typography, Select} from "@material-ui/core";
import {Input} from "@material-ui/core";
// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import {Table} from "@material-ui/core";
import { DataGrid, GridColDef, GridValueGetterParams} from '@material-ui/data-grid';
// data
import mock from "../dashboard/mock";
import { createTheme } from '@material-ui/core/styles'
import MenuButton  from "@material-ui/icons/Menu"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";
import {
  Paper,
  Menu,
  MenuItem,
  FormControlLabel,
  FormGroup,
  FormControl
} from "@material-ui/core";
import axios from 'axios';
import './styles.css';
import { ToastContainer, toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },
  datepicker: {
    backgroundColor: theme.palette.primary.main,
  },

}))



export default function Oceny(props) {
  registerLocale("pl", pl);
  const Temat = useRef([]);
  const Grupa = props.grupa;

  const [Uczestnicy2, setUczestnicy2] = useState([props.uczestnicy]);
  const [UczestnicyBase2, setUczestnicyBase2] = useState([]);
  const [data, setData] = useState([])
  const notifySuccess = (text) => toast.success(text, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
    const notifyError = (text) => toast.error(text, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });

     
  const GetBase = () => {


    axios.get(`http://mobileappscrapper.herokuapp.com/setuczestnicy/get` + "_" + Grupa + "_")
      .then(res2 => {
        const res = JSON.parse(res2.data[0].Uczestnicy);

        const newUczestnicy = res.map(element => ({
          id: element.id,
          name: element.name,
          active: element.active,
          obecnosc: false,
          spozniony: false,
          odrabianie: false,
          uwagi: "",
        }));
        setUczestnicy2(newUczestnicy);
        setUczestnicyBase2(newUczestnicy);
        setData(Uczestnicy2.map(participant => [participant.name, participant.ocena1, participant.ocena2]));
      })
      .then(()=> {console.log(Uczestnicy2)})
      .catch(error => {
        console.error(error);
      });
    };

  const columns = [{name:"Imię i nazwisko"}, {name: "I semestr",
  options: {
    filter: false,
    sort: false,
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
<TextField
        value={value}
        onBlur={event => updateValue(event.target.value)}
        multiline
      >
      </TextField>
      );
}}}, {name: "II Semestr",
options: {
  filter: false,
  sort: false,
  customBodyRender: (value, tableMeta, updateValue) => {
    return (
      <TextField
        value={value}
        onBlur={event => updateValue(event.target.value)}
        multiline
      >
        
      </TextField>
    );
}}}]
  const options = {  
    filterType: "checkbox",
              pagination: false,
              filter: false,
              isRowSelectable: false,
              print:false,
              search:true,
              sortOrder:"desc",
              viewColumns:false,
              downloadOptions: {
                filename: 'Oceny_' + props.grupa + new Date().toISOString().slice(0, 10) + '.csv'
              },
              onDownload: (buildHead, buildBody, columns, data) => {
                return "\uFEFF" + buildHead(columns) + buildBody(data);
              },
              customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
              
                return(
                  <Button style={{color:"white"}} color="primary" variant="contained" onClick={()=>{GetBase(); console.log(Uczestnicy2)}}>Wczytaj Oceny</Button>
                )
              },            
              textLabels: {
                pagination: {
                  next: "Następna strona",
                  previous: "Poprzednia strona",
                  rowsPerPage: "Wiersze na stronę:",
                  displayRows: "z" // 1-10 of 30
                },
                toolbar: {
                  search: "Szukaj",
                  downloadCsv: "Pobierz CSV",
                  print: "Drukuj",
                  viewColumns: "Zobacz kolumny",
                  filterTable: "Filtruj tabelę"
                },
                filter: {
                  title: "FILTRY",
                  reset: "reset",          
                },
                viewColumns: {
                  title: "Pokaż kolumny"
                },
                selectedRows: {
                  text: "wierszy usuniętych",
                  delete: "Usuń"
                }
            }
};
return (<>




<MUIDataTable title="Ocena" data={data} columns={columns} options={options}/>







</>) }