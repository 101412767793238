import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Schedule as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  Notifications as NotificationImportant,
  Lens as Lens,
  Settings as Settings,
  AccountTree as Account,
  Group as Group,
  Event as Event,
  Person as Person,
  GroupAdd as GroupAdd,
  School as School
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  { id: 0, label: "Panel Administracyjny", type: "title", icon: <Settings />, adminOnly: true}, 
  { id: 1, label:"Uczestnicy", link: "/app/grupatestowa", icon:<Person />, adminOnly: true}, 
  { id: 2, label:"Manager grup", link:"/app/managergrup", icon:<GroupAdd/>, adminOnly: true}, 
  {id: 3, label:"Manager lekcji", link:"/app/managerlekcji", icon:<School/>, adminOnly: true},
  
  
  { id: 4, type:"divider"},
  { id: 5, label: "Plan zajęć", link: "/app/plan", icon: <HomeIcon />},
  
  {
    id: 6,
    label: "Aktualności",
    link: "/app/aktualnosci",
    icon: <NotificationImportant />,
  },
  {
    id: 7,
    label: "Frekwencja",
    link: "/app/frekwencja",
    icon: <Event />,
  },
  {
    id: 8,
    label: "Wychowankowie",
    link: "/app/wychowankowie",
    icon: <Person />,
  },
  {
    id: 9,
    type:"divider"
  },
  {
    id:10,
    type:"title",
    label:"Grupy"
  }
  
];
let filteredStructure;
if((localStorage.getItem("email") == "damianchymkowski@gmail.com" || localStorage.getItem("email") == "biuro@dogomama.com" || localStorage.getItem("email") == "katarzyna.zaskorska@gmail.com" || localStorage.getItem("email") == "katarzyna.zbrzozka@gmail.com" || localStorage.getItem("email") == "biuro@ekoland.edu.pl" || localStorage.getItem("name") == "EkoAdmin"))
{
  filteredStructure = structure;
}
else
{
  filteredStructure = structure.filter(link => !link.adminOnly);
  
}



function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  const [isMobile, setIsMobile] = useState(window.innerWidth < theme.breakpoints.values.md);
  const [groups, setGroups] = useState([]);
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(() => {
    if((localStorage.getItem("email") == "damianchymkowski@gmail.com" || localStorage.getItem("email") == "biuro@dogomama.com" || localStorage.getItem("email") == "katarzyna.zaskorska@gmail.com" || localStorage.getItem("email") == "katarzyna.zbrzozka@gmail.com" || localStorage.getItem("email") == "biuro@ekoland.edu.pl" || localStorage.getItem("name") == "EkoAdmin"))
{
    filteredStructure = structure;
  }
  else
  {
    filteredStructure = structure.filter(link => !link.adminOnly);
    
  }
  }, [filteredStructure]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  }, []);
  useEffect(() => {
    async function fetchGroups() {
      try {
        const response = await fetch('https://mobileappscrapper.herokuapp.com/Eko_get_all_groups');
        const groupData = await response.json();
      
        console.log('Group Data:', groupData);
      
        // Extracting group data from the fetched data
        const groupsWithData = Object.values(groupData).map(group => JSON.parse(group));
      
        setGroups(groupsWithData);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    }

    fetchGroups();
  }, []);


  const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const c = (hash & 0x00FFFFFF).toString(16).toUpperCase();
    return `#${"00000".substring(0, 6 - c.length)}${c}`;
  };

  const sortedGroups = [...groups].sort((a, b) => a.name.localeCompare(b.name));
  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={isMobile ? classes.mobileBackButton : classes.hideOnMobile}>
  <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
    <ArrowBackIcon
      classes={{
        root: classNames(classes.headerIcon, classes.headerIconCollapse),
      }}
    />
  </IconButton>
</div>
      <List onClick={() => {if(isMobile){toggleSidebar(layoutDispatch)}}} className={classes.sidebarList}>
        {[...filteredStructure, ...sortedGroups.map((group, index) => ({
          id: `group-${index}`,
          label: group.name,
          link: `/app/group/${group.id}`,
          icon: <Group style={{ color: stringToColor(group.name) }} />,
          groupName: group.name,
          groupUsers: group.users,
          
        }))].map((link) => (
          <SidebarLink 
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            groups={groups}
            {...link}
            
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;
  
    if (isSmallScreen) {
      setPermanent(false);
    } else {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
