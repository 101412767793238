import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  IconButton,
  TableCell,
  Input
} from "@material-ui/core";
import { ToastContainer, toast } from 'react-toastify';
import MUIDataTable from "mui-datatables";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";
import { Delete } from "@material-ui/icons";

// styles
import useStyles from "./styles";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import PlanZajec1 from "./PlanZajec1.webp"
import PlanZajec2 from "./PlanZajec2.webp"
import PlanZajec3 from "./PlanZajec3.webp"
import PlanZajec4 from "./PlanZajec4.webp"
import axios from "axios";
import { Checkbox, TextField } from "@material-ui/core";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
export default function Aktualnosci(props) {

  const [open, setOpen] = useState(false);

  var classes = useStyles();
  var theme = useTheme();
  const [data, setData] = useState([]);
  const tableRef = useRef()
  const news = useRef();
  const newsToDelete = useRef();
  const [newsContent, setNewsContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [admin, setAdmin] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if((localStorage.getItem("email") == "damianchymkowski@gmail.com" || localStorage.getItem("email") == "biuro@dogomama.com" || localStorage.getItem("email") == "katarzyna.zaskorska@gmail.com" || localStorage.getItem("email") == "katarzyna.zbrzozka@gmail.com" || localStorage.getItem("email") == "biuro@ekoland.edu.pl" || localStorage.getItem("name") == "EkoAdmin"))
    {
      setAdmin(true)
    }
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result;
        setUploadedImage(base64Image);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleNewsContentChange = (event) => {
    setNewsContent(event.target.value);
  };
  const handleCreateNewsClick = () => {
    if (newsContent.trim() === "") {
      notifyError("Proszę dodać treść posta");
      return;
    }
  
    // Generate the current date and time
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}.${currentDate.getMonth() + 1}.${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}`;
  
    // Include the date and time in the news content
    const finalNewsContent = `${formattedDate} - ${newsContent}`;
  
    // Call the CreateNews function to create the news
    CreateNews(newsContent);
  setUploadedImage("")
    // Clear the news content input
    setNewsContent("");
  };

  const notifySuccess = (text) => toast.success(text, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
  const notifyError = (text) => toast.error(text, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      useEffect(() => {
        axios.get("https://mobileappscrapper.herokuapp.com/aktualnosci")
          .then((res) => {
            const response = res.data;
      
            const newsData = Object.keys(response).map((key) => {
              const item = response[key];
              return [item.Id, item.Date, item.News, item.Image];
            });
      
            setData(newsData);
            setIsLoading(false)
          });
      }, []);

  function DeleteNews(text) {
    const finalNews = {
      delset: "del",
      id: text, // Assuming that text is the unique identifier
    };
  
    axios.post("https://mobileappscrapper.herokuapp.com/aktualnosci", finalNews)
      .then((res) => {
        const persons = res.data;
        setData([]);
        for (let element in persons) {
          if (persons[element].Id !== "0") {
            let date = new Date(persons[element].Date);
            let dateToPrint = date.toLocaleDateString();
            setData((data) => [
              ...data,
              [persons[element].Id, persons[element].Date, persons[element].News, persons[element].Image],
            ]);
          }
        }
        notifyError('News usunięty!');
      });
  }


  
function CreateNews(text) {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear().toString();
  var time = String(today.getHours()).padStart(2, '0') + ":" + String(today.getMinutes()).padStart(2, '0');
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  let id = getRandomInt(999999);


  
  const newsData = {
    delset: "set", // Specify 'set' for creating news
    date: `${dd}/${mm}/${yyyy}`,
    content: text,
    id: id.toString(),
    image: uploadedImage, // You can pass the image URL here if available
  };

  axios.post("https://mobileappscrapper.herokuapp.com/aktualnosci", newsData)
    .then((res) => {
      const persons = res.data;
      const newData = [];

      for (let element in persons) {
        if (persons[element].Id !== "0") {
          let dateToPrint = today.toLocaleDateString();
          newData.push([
            persons[element].Id,
            dateToPrint,
            persons[element].News,
            persons[element].Image, // Update the table data with the new image URL
          ]);
        }
      }
      setData(newData);
      notifySuccess('News opublikowany!');
    });
}

  return (
    <>
      <Grid container>
        <PageTitle title="Aktualności"></PageTitle>
       
        <Widget style={{width:"100%", display:"flex",alignItems:"center",justifyContent:"center"}}>
          <Grid item xs>
          {isLoading ? (<div>
                <Skeleton height={40} width={200} />
                <Skeleton count={5} height={50} style={{ marginBottom: '10px' }} />
              </div>) : (<> <MUIDataTable
  ref={tableRef}
  title="Aktualności"
  data={data}
  columns={[
    { name: "Id", options: { display: false } },
    {
      name: "Data",
      options: {
        display: true,
        customBodyRender: (value) => {
          // Assuming the date is in the format "27/10/2023"
          // You can format it to your preferred display format
          const [day, month, year] = value.split('/');
          return `${day}.${month}.${year}`;
        },
      },
    },
    {
      name:"News"
    },
    {
      name: "",
      options: {
        display: true,
        customBodyRender: (value) => {
          if (value) {
            // If there is an image, render it
            return (
              <div>
              <img
                src={value}
                alt="News Image"
                style={{ width: "100px", cursor: "pointer" }}
                onClick={handleClickOpen}
              />
              <Dialog
                open={open}
                onClose={handleClose}
              >
                <DialogTitle></DialogTitle>
                <DialogContent>
                  <img
                    src={value}
                    alt="News Image"
                    style={{ width: "100%" }}
                  />
                </DialogContent>
              </Dialog>
            </div>
            );
          }
          return "Brak obrazka"; // Display if there's no image
        },
      },
    },
    {
      name: "Akcje", // Custom column for delete action
      options: {
        display: true,
        customBodyRender: (value, tableMeta) => {
          const rowData = data[tableMeta.rowIndex];
          const userEmail = localStorage.getItem("email");
  

            return (
            
              <Button
              onClick={() => {
                if (admin && window.confirm("Czy chcesz usunąć ten news?")) {
                  DeleteNews(rowData[0].toString());
                }
              }}
              disabled={!admin} // Disable the button if not an admin
            >
              Usuń
            </Button>
              
            );
          
  
         
        },},}
  ]}
  options={{
    sortOrder: {
      name: 'Data',
      direction: 'desc',
    },
    filterType: false,
    selectableRows: "none",
    pagination: false,
    filter: false,
    isRowSelectable: false,
    download: false,
    print: false,
    search: false,
   
    rowHover: true,
    viewColumns: false,
    downloadOptions: {
      filename: 'Frekwencja_' + new Date().toISOString().slice(0, 10) + '.csv',
    },
    textLabels: {
      body: {
        noMatch: "Nie ma żadnych newsów :(",
        toolTip: "Sortuj",
      },
      toolbar: {
        search: "Szukaj",
        downloadCsv: "Pobierz",
      },
    },
  }}
/></>)}
         
          </Grid>
        </Widget>
      </Grid>
<Grid>
  {admin ? (<><Widget>
  <Grid item xs>
  <TextField
    style={{ backgroundColor: "white" }}
    placeholder="Treść newsa"
    fullWidth
    value={newsContent}
    onChange={handleNewsContentChange}
  />
  <Input
    type="file"
    accept="image/*"
    style={{ display: 'none' }}
    onChange={handleImageUpload}
    id="image-input"
  />
  <label htmlFor="image-input">
    <Button
      color="primary"
      variant="contained"
      component="span"
      style={{ color: "white", padding: "10px", marginTop: "10px", width: "100%" }}
    >
      {uploadedImage ? "Zmien obraz" : "Wybierz obraz"}
    </Button>
  </label>
  <Button
    color="primary"
    variant="contained"
    style={{ color: "white", padding: "10px", marginTop: "10px", width: "100%" }}
    onClick={handleCreateNewsClick}
  >
    Stwórz newsa
  </Button>
</Grid>
       </Widget></>) : (<></>)}
  
</Grid>
       
          
    </>
  );
}
